import { axiosInstance as api } from "./api";
import reduxStore from "../store";
import { removeLoading } from "../store/modules/loading/action";
import { toast } from "react-toastify";

const { dispatch } = reduxStore;
const interceptor = api.interceptors.response.use(
  function(response) {
    dispatch(removeLoading());
    return response;
  },
  function(error) {
    dispatch(removeLoading());
    console.log("erro =<", error.response.status);
    if (error.response.status === 500) {
      toast.error(
        "Erro inesperado, se o problema persistir procure o administrador do sistema."
      );
      return Promise.reject(error);
    }
    if (error.response && error.response.data.tokenInvalido) {
      localStorage.clear();
      window.location = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default interceptor;
