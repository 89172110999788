import React from "react";
import { useDispatch } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import Link from '@material-ui/core/Link';


import { ErrorMessage, Formik, Form as FormikForm, Field } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";

import { addToken } from "../../store/modules/auth/action";
import { addUser } from "../../store/modules/usuario/action";
import api from "../../services/api";

import { Button, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: '80%'
  },
  cardForm: {
    background: "#f4f6f8",
    margin: 10,
    width: '90%',
    padding: 15
  },

  form: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    maxWidth:500
  },
  title: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginTop: theme.spacing(2),
  },

  submitButton: {
    margin: theme.spacing(2, 0),
    background: "#977D57",
    color: "#ffffff",
  },
  image: {
    height: 150,
    width: 150,
    marginRight: 10,
    borderRadius: "50%",
  },

  spanError: {
    color: "red",
    fontSize: 18,
  },
}));

const validations = yup.object().shape({
  email: yup
    .string()
    .email("Email é invalido")
    .required("O email é obrigatorio"),
  senha: yup.string().required("Senha é obrigatória"),
});

const Login = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory()

  const inicialValues = {
    email: "",
    senha: "",
  };

  const handleSubmit = (values) => {
    api
      .post("login", values)
      .then((response) => {
        console.log(response)
       const token = response.headers.authorization 
       const user = {
          nome: response.data,
          email:values.email
       }
        dispatch(addToken(token));
       dispatch(addUser(user));
        history.push("/")
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };


  return (
    <div className={classes.content}>
      <Formik
        initialValues={inicialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {() => (
          <FormikForm className={classes.form}> 
            <Card className={classes.cardForm}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    className={classes.image}
                    alt="Logo"
                    src="/images/logos/logo.png"
                  />
                </div>

                <div>
                  <Field
                    className={classes.textField}
                    label="Seu email"
                    fullWidth
                    name="email"
                    size="small"
                    type="text"
                    as={TextField}
                    variant="outlined"
                  />
                </div>
                <ErrorMessage
                  className={classes.spanError}
                  component="span"
                  name="email"
                />
                <div>
                  <Field
                    className={classes.textField}
                    label="Sua senha"
                    fullWidth
                    name="senha"
                    size="small"
                    type="password"
                    as={TextField}
                    variant="outlined"
                  />
                </div>
                <ErrorMessage
                  className={classes.spanError}
                  component="span"
                  name="senha"
                />
                <Button
                  style={{marginTop: 10, marginBottom: 10, backgroundColor:'#FFC300', color: '#ffff' }}
                  fullWidth
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Entrar
                </Button>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                  <Link onClick={() => history.push('formulario-cadastro-grupo')}>
                    Não tenho Cadastro
                  </Link>
                  <Link onClick={() => history.push('/nova-senha-grupo')}>
                    Esqueci minha senha
                  </Link>
                </div>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(Login);
