import React from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'

const ComponentMaisOpcoes = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { opcoes = [] } = props
  return (
    <React.Fragment>
      <IconButton
        id={'menu-opcoes'}
        aria-controls={anchorEl ? 'menu-opcoes' : undefined}
        color="primary"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="card-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {opcoes.map((item) => (
          <MenuItem id={item.id} key={item.label} onClick={item.callback}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}

export default ComponentMaisOpcoes
