import {
  adicionarUsuarioStorage,
  limparLocalStorage,
} from "../../../util/LocalStoreUtil";

export function addUser(grupo) {
  const user = {
    nome: grupo.nome,
    email: grupo.email,
    administrador: (grupo.email === 'daylansantos@gmail.com' ? true : false)
  };
  adicionarUsuarioStorage(user);
  return {
    type: "@ADD_USER",
    payload: user,
  };
}

export function removeUser() {
  limparLocalStorage();
  return {
    type: "@REMOVE_USER",
    payload: "",
  };
}
