import React from "react";
import { Typography } from "@material-ui/core";

const SobreSistema = () => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography>
          SISTEMA PARA CARTEIRINHA PARA O CICLISTA
        </Typography>
        <Typography>Versão: 1.0</Typography>
        <span>Todos os direitos reservados à DAILAN BUENO DOS SANTOS</span>
        <span>EMAIL: daylansantos@gmail.com</span>
      </div>
    </>
  );
};

export default SobreSistema;
