/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import { CardActions } from "@material-ui/core";

import { toast } from "react-toastify";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as yup from "yup";

import IdIcon from "../imagens/IdIcon.png";
import { inputCelular } from "../../components/Telefone";
import { AsyncSelectBasicoFormik } from '../../components/AsyncSelectBasico'
import If from "../../util/If";
import api from '../../services/api'
import ModalConfirmacao from "../../components/ModalConfirmacao";

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: "100%",
    alignItems: 'center',
    margin: 0,
    paddingTop: 50,
    marginBottom: 30,
    padding:15
  },
  form: {
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
    alignItems: 'center', 
    flex: 1,
    margin: 10,
    marginTop: 5,
    margionBottom: 50
  },
  rootTitulo: {
    width: "98%",
    margin: 0,
  },
  subTitulo: {
    marginTop: 20,
    margionBottom: 20
  },
  divCheck: {
    margin: 'center'
  },
  subContainer: {
    width: '97%'
  }
}));

const validaCampos = yup.object().shape({
  nome: yup.string().required("Nome é obrigatório"),
  cidade: yup.string().required("O campo cidade é obrigatório"),
  nomeEmergencia: yup
    .string()
    .required("Você precisa informa o nome de alguém para caso de emergência."),
  telefoneEmergencia: yup
    .string()
    .required(
      "Você precisa informa o telefone de alguém para caso de emergência."
    ),
  telefone: yup
  .string()
  .required(
    "Você precisa informa o seu número de telefone."
  ),

  email: yup
  .string()
  .email('O email é invalido!')
  .required(
    "Você deve informa o email do ciclista."
  ),

});

export function FormularioCadastroCiclista(props) {
  const classes = styles();
  const history = useHistory()
  const { value = '' } = props
  const [imagem, setImage] = useState("");
  const [matricula, setMatricula] = useState('')
  const [checkAlergia, setCheckAlergia] = useState(false)
  const [checkControlado, setCheckControlado] = useState(false)
  const [checkTipoSangue, setCheckTipoSangue] = useState(false)
  const [checkDoenca, setCheckDoenca]= useState(false)
  const [confirmacao, setConfirmacao] = useState(false)
  const [novoCiclista, setNovoCiclista] = useState('')
  const [capche, setCache] = useState('')

  const objetoIncial = {
    id: value.id || null,
    matricula: value.matricula || null,
    nome: value.nome || '',
    email: value.email || '',
    telefone: value.telefone,
    nomeEmergencia: value.nomeEmergencia || '',
    telefoneEmergencia: value.telefoneEmergencia || '',
    tipoSangue: value.tipoSangue || '',
    planoSaude: (value.planoSaude &&  value.planoSaude === 'SIM' ? true : false),
    alergia: value.alergia || '',
    medicamento: value.medicamento || '',
    doencas: value.doencas || '',
    cidade:  value.cidade || '',
    grupo: value.grupoDto || ''
  };

  const montarCheks =() => {
    if(value.doencas) {
      setCheckDoenca(true)
    }
    if(value.alergia) {
      setCheckAlergia(true)
    }
    if(value.controlado) {
      setCheckControlado(true)
    }
    value && setImage(value.imagem)
   }  

  const uploadImagem = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setImage('');
      return
    }
    if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      toast.error("Sua foto deve ser um desses formatos jpeg, png ou jpg");
      setImage('');
      return 
    }  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    };
  };

  const handleSalvar = (novoCicli) => {
    const values =  novoCicli || novoCiclista
    const idGrupo =  values.grupo && values.grupo.id
    if (!imagem) {
      setConfirmacao(false)
      toast.error("Você deve tirar uma foto para a a identidade");
      return;
    }
    if(!checkTipoSangue && !values.tipoSangue) {
      toast.error("Você deve preencher as informações sobre o tipo sanguíneo ");
      return;
    }
    !idGrupo && toast.warn(`${values.nome}, evite pedalar sozinho é perigoso :)`) 
    const  temPlanoSaude =  values.planoSaude ? 'SIM' : 'NAO'
    const ciclista = {
      ...values,
      imagem,
      doencas: checkDoenca ? values.doencas : null,
      alergia: checkAlergia ? values.alergia : null,
      medicamento: checkControlado ? values.medicamento : null,
      idGrupo,
      tipoSangue: !checkTipoSangue ? values.tipoSangue : 'Não sabe',
      planoSaude: temPlanoSaude,
    };
   !value ? salvar(ciclista) : alterar(ciclista)
  };

  const handleOperacaoSalvar = (values) => {
    if(!capche){
      toast.error("Preencha o campo não sou um robô")
      return;
    }
    setNovoCiclista(values)
    if(values.grupo) {
      handleSalvar(values)
      return
    }
    setConfirmacao(true)
  }


  const salvar = (ciclista) => {
    setConfirmacao(false)
    api
    .post("/api/ciclistas", ciclista)
    .then((res) => {
      setMatricula(res.data.matricula)
      toast.success(`${ciclista.nome}, você foi cadastrado com sucesso, estamos gerando sua carteirinha`);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      history.push(`/identidade-ciclista/?codMatricula=${res.data.matricula}`)
    })
    .catch((resp) => {
      toast.error(resp.response.data.message);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    });
  }

  const alterar = (ciclista) => {
    api
    .post("/api/ciclistas/alterar", ciclista)
    .then((res) => {
      setMatricula(res.data.matricula)
      toast.success(`${ciclista.nome}, alteração efetuada com sucesso!`);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      history.push(`/identidade-ciclista/?codMatricula=${res.data.matricula}`)
    })
    .catch((resp) => {
      toast.error(resp.response && resp.response.data.message);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    });
  }

  const getMessage = ()=> {
    return `${novoCiclista.nome},vejo que você não informou um grupo, Não é integrante de um grupo? você é um ciclista solitário?`
  }

  useEffect(() => {
    montarCheks()
  },[])

  function onChange(value) {
    if(value) {
      setCache(value)
    }
  }

  return (
    <>
      <div className={classes.container}>
        
          <Typography variant="h6">Equipamentos de segurança podem salva a sua vida, use sempre! :)</Typography>
          <If test={!matricula}>
            <div style={{ width: '100%', height: 100, marginTop: 20,display: 'flex', alignItems: 'center', paddingLeft: '20%', backgroundColor: '#eeee' }}>
              <Typography variant="subtitle1">CADASTRO DO CICLISTA</Typography>
            </div>
          </If>

           <If test={matricula}>
            <div style={{ width: '100%',height: 100, marginTop: 20,display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#eeee' }}>
              <Typography variant="h6">MATRÍCULA</Typography>
              <Typography variant="h5">{matricula}</Typography>
              <Typography variant="body">Você deve anotar o número da sua matrícula, pois vai precisar dela para gerar a sua carteria.</Typography>
            </div>
           </If>
                    
          <Formik
            initialValues={objetoIncial}
            onSubmit={handleOperacaoSalvar}
            validationSchema={validaCampos}
          >
            {({ values }) => (
              <FormikForm>
                <div className={classes.form}>

                <div className={classes.subContainer}>
                      <Typography variant="h6" className={classes.subTitulo}>
                         Informações do ciclista
                      </Typography>
                        <div style={{ padingTop: 20 }}>
                          <div style={{ display: 'flex', alignItems: 'center', flexDirection:'column' }}>
                              <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 200,
                                    height: 180,
                                    margin: 20,
                                    backgroundColor: "#FCFCF4",
                                  }}
                                >
                              {imagem ? (
                                <img
                                  style={{
                                    heigth: '100%',
                                    width: '100%',
                                    maxWidth: 180,
                                    maxHeight:165,
                                    borderRadius: 5,
                                    border: '1px solid yellow'
                                  }}
                                  alt="imagem"
                                  src={imagem}
                                />
                              ) : (
                                <>
                                  <img
                                    src={IdIcon}
                                    alt="ID"
                                    style={{ width: 130, height: 130 }}
                                  />
                                  <div style={{ color: "red" }}>
                                    <ErrorMessage component="span" name="imagem" />
                                  </div>
                                </>
                              )}
                            </div>

                              <div
                                style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                }}
                              >
                            <Typography variant="subtitle1">Dica: fotos que foram tiradas do peitoral para cima fica melhor na sua caterinha</Typography>
                            <Typography variant="subtitle1">Observe o desenho da imagem!</Typography>
                              <Field
                                as={TextField}
                                style={{ marginTop: 5 }}
                                variant="outlined"
                                type="file"
                                name="imagem"
                                value={values.imagem}
                                accept="image/png, image/jpeg"
                                placeholder="Fazer upload imagem"
                                onChange={uploadImagem}
                              />
                  
                          </div>
                      
                        </div>

                          <Typography variant="subtitle1">Nome que vai aparecer na sua carteirinha</Typography>
                          <Field
                            as={TextField}
                            style={{ marginTop: 8, marginBottom: 20 }}
                            name="nome"
                            value={values.nome}
                            placeholder="Exemplo: CICLISTA SANTOS DA SILVA"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage component="span" name="nome" />
                          </div>

                          <Typography variant="subtitle1">
                            Seu número de telefone
                          </Typography>
                          <Field
                            as={inputCelular}
                            style={{ marginTop: 8, marginBottom: 20 }}
                            name="telefone"
                            value={values.telefone}
                            placeholder="Exemplo: (61) 99999-9999"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                          <div style={{ color: "red", marginBottom: 8 }}>
                            <ErrorMessage
                              component="span"
                              name="telefone"
                            />
                          </div>

                          <Typography variant="subtitle1">
                            Seu e-mail (deve ser um e-mail existente)
                          </Typography>
                          <Field
                            as={TextField}
                            style={{ marginTop: 8, marginBottom: 20 }}
                            name="email"
                            value={values.email}
                            placeholder="ciclista@gmail.com"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                          <div style={{ color: "red", marginBottom: 8 }}>
                            <ErrorMessage
                              component="span"
                              name="email"
                            />
                          </div>

                          <Typography variant="subtitle1">
                            Senha (Essa senha será usada sempre você que precisar alterar o seu cadastro)
                          </Typography>
                          <Field
                            as={TextField}
                            style={{ marginTop: 8, marginBottom: 20 }}
                            name="chave"
                            value={values.chave}
                            type="password"
                            variant="outlined"
                            size="small"
                            fullWidth
                          />

                          <Typography variant="subtitle1">
                            Você participa de algum grupo de ciclistas (digite parte do nome)
                          </Typography>
                          <Field
                            name="grupo"
                            style={{ marginTop: 8, marginBottom: 20 }}
                            label="Digite o nome do grupo"
                            placeholder="Pesquisa pelo nome do grupo, se não encontrar pessa para o administrador do grupo se cadastra com agente."
                            propriedadeItemLabel={['nome']}
                            urlBusca="/api/grupos/nome/"
                            component={AsyncSelectBasicoFormik}
                            fullWidth
                          />
                          <Typography variant="subtitle1">Tipo sanguíneo (<Checkbox checked={checkTipoSangue} onChange={(event) => setCheckTipoSangue(!checkTipoSangue) }/>  Não sei meu tipo sanguíneo )</Typography>
                    
                          <Field
                            as={TextField}
                            style={{ marginTop: 8, marginBottom: 20 }}
                            name="tipoSangue"
                            value={values.tipoSangue}
                            disabled={checkTipoSangue}
                            variant="outlined"
                            placeholder="Digite aqui seu tipo sanguíneo"
                            size="small"
                            fullWidth
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage component="span" name="tipoSangue" />
                          </div>

                          <Typography variant="subtitle1">Cidade (Cidade onde você vive)</Typography>
                            <Field
                              as={TextField}
                              style={{ marginTop: 8, marginBottom: 20 }}
                              name="cidade"
                              value={values.cidade}
                              placeholder="Exemplo: Sobradinho, DF"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          <div style={{ color: "red" }}>
                            <ErrorMessage component="span" name="cidade" />
                          </div>
                    </div>
                    </div>             
                 
                    <hr style={{ width: '97%',marginTop: 20, color: '#eee'}} />

                   <div className={classes.subContainer}>
                    <Typography variant="h6" className={classes.subTitulo}>
                      Informações de emergência
                      </Typography>
                      <Typography variant="subtitle1">
                        Nome contato emergência
                      </Typography>
                      <Field
                        as={TextField}
                        style={{ marginTop: 8, marginBottom: 20 }}
                        name="nomeEmergencia"
                        value={values.nomeEmergencia}
                        placeholder="Exemplo: MARIA DAS GRAÇAS"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <div style={{ color: "red" }}>
                        <ErrorMessage component="span" name="nomeEmergencia" />
                      </div>

                      <Typography variant="subtitle1">
                        Telefone emergência
                      </Typography>
                      <Field
                        as={inputCelular}
                        style={{ marginTop: 8, marginBottom: 20 }}
                        name="telefoneEmergencia"
                        value={values.telefoneEmergencia}
                        placeholder="Exemplo: (61) 99999-9999"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <div style={{ color: "red", marginBottom: 8 }}>
                        <ErrorMessage
                          component="span"
                          name="telefoneEmergencia"
                        />
                      </div>
                    </div>
           
                  <hr style={{ width: '100%',marginTop: 20, color: '#eee'}} />
              
                <div className={classes.subContainer}>
                      <Typography variant="h6" className={classes.subTitulo}>
                        Informações de saúde
                      </Typography>

                      <div className={classes.divCheck}>
                        <Field
                          as={Checkbox}
                          name="planoSaude"
                          checked={values.planoSaude}
                          fullWidth
                        />
                        <Typography variant="body">
                         Hey você tem plano de saúde?
                        </Typography>
                      </div>

                  
                      <Typography variant="subtitle1">
                         <Checkbox checked={checkAlergia} onChange={(event) => setCheckAlergia(event.target.checked)} />
                         Hey você tem alergia a algum medicamento?
                      </Typography>
                      <If test={checkAlergia}>
                        <Field
                          as={TextField}
                          style={{ marginTop: 8, marginBottom: 20 }}
                          name="alergia"
                          value={values.alergia}
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Descreva aqui: dipirona,Aspirina"
                          size="small"
                          helperText="Atenção, não preencher o campo  caso não tenha."
                          fullWidth
                        />
                      </If>
                    
                      <Typography variant="subtitle1">
                       <Checkbox checked={checkControlado} onChange={(event) => setCheckControlado(event.target.checked) }/>
                        Hey você faz uso de algum medicamento controlado ?
                      </Typography>
                      <If test={checkControlado}>
                        <Field
                          as={TextField}
                          style={{ marginTop: 8, marginBottom: 20 }}
                          name="medicamento"
                          value={values.medicamento}
                          variant="outlined"
                          multiline
                          rows={3}
                          placeholder="Descreva aqui: exemplos: dipirona,Novalgina,Advil,Aspirina"
                          size="small"
                          helperText="Atenção, não preencher caso não faz uso de medicamentos controlados."
                          fullWidth
                        />
                      </If>

                      <Typography variant="subtitle1">
                      <Checkbox checked={checkDoenca} onChange={(event) => setCheckDoenca(event.target.checked) }/>
                        Você tem alguma doença que seja importante
                        para socorrista ?
                      </Typography>
                      <If test={checkDoenca}>
                      <Field
                        as={TextField}
                        style={{ marginTop: 8, marginBottom: 20 }}
                        variant="outlined"
                        name="doencas"
                        value={values.doencas}
                        multiline
                        rows={3}
                        placeholder="Descreva aqui: exemplos: diabetes, problema de coração."
                        size="small"
                        helperText="Atenção, não preencher caso não possui doenças "
                        fullWidth
                      />
                      </If>
                    </div>
                    <ReCAPTCHA
                      sitekey="6LdGv1MaAAAAAFWW5BBttjU0A03RR3ROVx_b7uGG"
                      onChange={onChange} />

                    <Typography style={{ color: '#FFC300' }} variant="h6"> 
                         Pronto! pressione o botão salvar para finalizar o cadastro
                    </Typography>    
           

                  <CardActions styles={{ marginBottom: 75 }} >                
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ fontSize:25, width: 300, height: 60, borderRadius: 40, backgroundColor: '#FFC300', color: '#ffff' }}
                      size="large"
                    >
                      Salvar
                    </Button>

                  </CardActions>
                
              </div>
              </FormikForm>
            )}
          </Formik>
      </div>
      <ModalConfirmacao
        openConfirmacao={confirmacao}
        texto={getMessage()}
        callbackConfirma={() =>handleSalvar()}
        callbackFechaModal={() => setConfirmacao(false) }
      >

      </ModalConfirmacao>
    </>
  );
}
export default FormularioCadastroCiclista;
