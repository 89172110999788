import React, { useState } from "react";
import {  useSelector } from "react-redux";
import {  useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Divider from '@material-ui/core/Divider';
import { Card, Typography } from "@material-ui/core";
import CropFreeIcon from '@material-ui/icons/CropFree';

import ciclistaIcon from "../imagens/ciclistaIcon.png";
import identidade from "../imagens/identidade.png";
import loopa from "../imagens/loopa.png";
import grupo from "../imagens/grupo.png";

import alteraCadastro from '../imagens/alteraCadastro.png'

import Logo from '../imagens/idCiclista.png'

import If from '../../util/If'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: 'wrap',
    width: '100%',
    padding:15
  },

  containerCard: {
    display: "flex",
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
  },

  apoio: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: 'wrap',
    marginTop: 10,
    padding: 15
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
    height: 140,
    margin: 13,
    borderRadius: 40,
    cursor: "pointer",
  },
  cardHover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
    height: 140,
    margin: 13,
    borderRadius: 40,
    backgroundColor: "#EFB766",
    cursor: "pointer",
  },
  icon: {
    width: 35,
    height: 35,
  },
  iconApoio: {
    marginTop:10,
    paddingRight: 10,
    width: 160,
    height: 140,
    borderRadius: '50%'
  },

  iconCriador: {
    marginTop:10,
    paddingRight: 10,
    width: 100,
    height: 80,
    borderRadius: '50%'
  },

  iconGrupo: {
    marginTop:10,
    paddingRight: 10,
    width: 110,
    height: 90,
    borderRadius: '50%'
  },

  cardGrupo: {
    display: 'flex',
    flexDirection:'column',
    margin:10,
    justifyContent: 'center',
    alignItems: 'center'
  },

  iconApoioHover: {
    marginTop:10,
    paddingRight: 10,
    width: 170,
    height: 150,
    borderRadius: 20,
    cursor: "pointer",
  },
  dividi: {
    height: 2
  }
}));

const DashBoardGerencial = () => {
  const classes = useStyles();
  const history = useHistory();
  const [hoverCad, setHoverCad] = useState(false);
  const [hoverFind, setHOverFind] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [hoverGrupo, setHoverGrupo] = useState(false);
  const [hoveAlterar, setHoveAlterar] = useState(false);
  const [hoverSelo, setHoverSelo] = useState(false);
  
  const auth = useSelector((state) => state.userReducer);
  const { usuario } = auth

  const handleRedirect = (url) => {
    history.push(url);
  }; 


  return (
    <div>
       <div className={classes.root}>
      <div className={classes.containerCard}>
        <Card
          onClick={() => handleRedirect("formulario-cadastro-ciclista")}
          className={hoverCad ? classes.cardHover : classes.card}
          onMouseEnter={() => setHoverCad(true)}
          onMouseLeave={() => setHoverCad(false)}
        >
          <img
            className={classes.icon}
            alt="IDENTIDADE DO CICLISTA"
            src={ciclistaIcon}
          />
          <Typography variant="caption">Cadastro do ciclista</Typography>
        </Card>
        <Card
          onClick={() => handleRedirect("detalhar-ciclista")}
          className={hoverFind ? classes.cardHover : classes.card}
          onMouseEnter={() => setHOverFind(true)}
          onMouseLeave={() => setHOverFind(false)}
        >
          <img className={classes.icon} alt="CONSULTA" src={loopa} />
          <Typography variant="caption">Consultar</Typography>
        </Card>

        <Card
          onClick={() => handleRedirect("identidade-ciclista")}
          className={hoverId ? classes.cardHover : classes.card}
          onMouseEnter={() => setHoverId(true)}
          onMouseLeave={() => setHoverId(false)}
        >
          <img
            className={classes.icon}
            alt="GERAR IDENTIDADE"
            src={identidade}
          />
          <Typography variant="caption">Gerar Identidade </Typography>
        </Card>
      </div>

      <If test={usuario.nome == null}>
          <div className={classes.containerCard}>
            <Card
                onClick={() => handleRedirect("cadastro-ciclista-alteracao")}
                className={hoveAlterar ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoveAlterar(true)}
                onMouseLeave={() => setHoveAlterar(false)}
              >
                <img className={classes.icon} alt="Altera" src={alteraCadastro} />
                <Typography variant="caption">Alterar meu cadastro</Typography>
            </Card>

            <Card
              onClick={() => handleRedirect("grupo-login")}
              className={hoverGrupo ? classes.cardHover : classes.card}
              onMouseEnter={() => setHoverGrupo(true)}
              onMouseLeave={() => setHoverGrupo(false)}
            >
              <img className={classes.icon} alt="GRUPOS" src={grupo} />
              <Typography variant="caption">Grupos</Typography>
            </Card>
          </div>
      </If>

      <If test={usuario.administrador}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Card
                onClick={() => handleRedirect("cadastro-ciclista-alteracao")}
                className={hoveAlterar ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoveAlterar(true)}
                onMouseLeave={() => setHoveAlterar(false)}
              >
                <img className={classes.icon} alt="Altera" src={alteraCadastro} />
                <Typography variant="caption">Alterar meu cadastro</Typography>
            </Card>


            <Card
              onClick={() => handleRedirect("meu-grupo")}
              className={hoverSelo ? classes.cardHover : classes.card}
              onMouseEnter={() => setHoverSelo(true)}
              onMouseLeave={() => setHoverSelo(false)}
            >
              <img className={classes.icon} alt="Integrantes do meu grupo." src={grupo} />
              <Typography variant="body1">Meu grupo</Typography>
            </Card>

            <If test={usuario.administrador}>
              <Card
                onClick={() => handleRedirect("gerador-qr-code")}
                className={hoverGrupo ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoverGrupo(true)}
                onMouseLeave={() => setHoverGrupo(false)}
              >
                <CropFreeIcon fontSize="large" />
                <Typography variant="caption">QR-CODE</Typography>
              </Card>
            </If>

          </div>
      </If>
    </div> 

    </div>
   
  );
};

export default DashBoardGerencial;
