import React, { useState, useEffect } from 'react'

import queryString from 'query-string'
import FormularioCadastroCiclista from './FormularioCadastroCiclista'
import api from '../../services/api'
import If from '../../util/If'
import { toast } from 'react-toastify'

const FormularioEdicaoCiclista = () => {
    const [objetoInicial, setObjetoInicial] = useState('')
    const { search } = window.location
    const { codMatricula } = queryString.parse(search)
    const { codSeg } = queryString.parse(search)

    useEffect(() => {
        if(!codMatricula) {
          return;
        }
        if (!codSeg) {
          return
        }
        api.get(`/api/ciclistas/matricula/${codMatricula}/cod-seguranca/${codSeg}`)
        .then((response) => {
          setObjetoInicial(response.data)
        }).catch(err => {
          toast.error(err.response.data.message || 'Falha na operação, se o problema persistir procurar o adm do sistema')
          console.log('error', err.response.data)
        })
      },[codMatricula, codSeg])

      useEffect(() => {
        if(!codMatricula) {
          return;
        }
        if (codSeg) {
          return
        }
        api.get(`/api/ciclistas/matricula/${codMatricula}`)
        .then((response) => {
          setObjetoInicial(response.data)
        }).catch(err => {
          toast.error(err.response.data.message || 'Falha na operação, se o problema persistir procurar o adm do sistema')
          console.log('error', err.response.data)
        })
      },[codMatricula, codSeg, search])


    return (
        <If test={objetoInicial}>
            <FormularioCadastroCiclista value={objetoInicial} />
        </If>
    )
}

export default FormularioEdicaoCiclista