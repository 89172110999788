import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const styles = (theme) => ({
  root: {
    backgroundColor: "#EFB766",
    color: "#ffffff",
    margin: 0,
    padding: theme.spacing(2),
  },
  titulo: {
    backgroundColor: "#EFB766",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export function ModalComponent(props) {
  const {
    callbackFechaModal,
    open,
    titulo,
    children,
    fullWidth = true,
    callbackFechaAlternativo,
  } = props;

  const handleClose = () => {
    if (callbackFechaModal) {
      callbackFechaModal(false);
    } else {
      callbackFechaAlternativo(false);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titulo}
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </div>
  );
}

ModalComponent.propTypes = {
  titulo: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  callbackFechaModal: PropTypes.func,
  callbackFechaAlternativo: PropTypes.func,
  open: PropTypes.bool.isRequired,
};

export default ModalComponent;
