import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Telefone from "../../components/Telefone";
import { CardActions } from "@material-ui/core";
import Link from '@material-ui/core/Link';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { toast } from "react-toastify";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

import Logo from "../imagens/logo.png";
import api from '../../services/api'
import { removeToken } from "../../store/modules/auth/action";
import { addUser } from "../../store/modules/usuario/action";

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: 30
  },
  card: {
    margin: 10,
    background: "#f4f6f8",
    padding: 15,
    width: '100%',
    maxWidth: 600,
  },
  rootTitulo: {
    width: "98%",
    margin: 10,
  },
}));

const validaCampos = yup.object().shape({
  nome: yup.string().required("Nome do grupo  é obrigatório"),
  email: yup
    .string()
    .required("E-mail é obrigatório")
    .email("O e-mail é invalido"),
  telefone: yup.string().required("O telefone é obrigatório"),
  senha: yup.string().required("A senha é obrigatória"),
});

export function FormularioCadastroGrupo(props) {
  const dispatch = useDispatch();
  const classes = styles();
  const { value = '' } = props
  const history = useHistory();
  const [imagem, setImage] = useState(value.logo || "");


  const objetoIncial = {
    log: value.logo || "",
    nome: value.nome || "",
    email: value.email || "",
    telefone: value.telefone  || "",
    endereco: value.endereco
  };

  const uploadImagem = (e) => {
    const file = e.target.files[0];

    if (!file) {
      setImage('');
      return
    }
    if (file.type !== 'image/jpg' && file.type !== 'image/jpeg' && file.type !== 'image/png') {
      toast.error("Sua logo deve ser um desses formatos jpeg, png ou jpg");
      setImage('');
      return 
    }  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImage(reader.result);
    };
  };

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(addUser({nome: null}));
  };

  const handleSalvar = (values) => {
    if (!imagem) {
      toast.error("A logo do seu grupo é obrigatória!");
      return
    }
    const grupo = {
      ...values,
      id: value && value.id,
      logo: imagem,
    };
    api
      .post("/api/grupos", grupo)
      .then((res) => {
        toast.success("Grupo cadastrado com sucesso!");
        handleRedirect("/grupo-login");
        handleLogout()
      })
      .catch((resp) => {
        toast.error(resp.response.data.message);
      });
  };

  const handleRedirect = (url) => {
    history.push(url);
  };

  return (
    <>
      <div className={classes.container}>
          <div style={{ width: '100%', height: 100, marginTop: 20,display: 'flex', alignItems: 'center', paddingLeft: '20%', backgroundColor: '#eeee' }}>
               <Typography variant="subtitle1">CADASTRO DO GRUPO DE CICLISTAS</Typography>
          </div>
        <div className={classes.card}>
          <Formik
            initialValues={objetoIncial}
            onSubmit={handleSalvar}
            validationSchema={validaCampos}
          >
            {({ values }) => (
                <FormikForm>
                    <div style={{ width: "100%", alignItems: 'center'}}>
                      <div style={{
                        width: '100%',
                        display:'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: 200,
                          height: 180,
                          margin: 20,
                          backgroundColor: "#FCFCF4",
                        }}
                      >
                        {imagem ? (
                          <img
                            style={{
                              heigth: '100%',
                              width: '100%',
                              maxWidth: 180,
                              maxHeight:165,
                              borderRadius: 5,
                              border: '1px solid yellow'
                            }}
                            alt="imagem"
                            src={imagem}
                          />
                        ) : (
                          <>
                            <img
                              src={Logo}
                              alt="ID"
                              style={{ width: 130, height: 130 }}
                            />
                            <div style={{ color: "red" }}>
                              <ErrorMessage component="span" name="imagem" />
                            </div>
                          </>
                        )}
                      </div>
                      </div>


                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Field
                          as={TextField}
                          style={{ marginTop: 5 }}
                          variant="outlined"
                          type="file"
                          name="imagem"
                          accept="image/*"
                          placeholder="Fazer upload imagem"
                          onChange={uploadImagem}
                        />
                      </div>
                      <Typography variant="subtitle1">Nome (Nome do seu grupo)</Typography>
                    
                      <Field
                        as={TextField}
                        name="nome"
                        placeholder="Exemplo: RADICAL BIKE"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <div style={{ color: "red" }}>
                        <ErrorMessage component="span" name="nome" />
                      </div>

                      <Typography variant="subtitle1">E-mail (deve ser um e-mail existente)</Typography>
                      <Field
                        as={TextField}
                        name="email"
                        placeholder="ciclista@gmail.com"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <div style={{ color: "red" }}>
                        <ErrorMessage component="span" name="email" />
                      </div>

                        <Typography variant="subtitle1">Senha</Typography>
                          <Field
                            as={TextField}
                            name="senha"
                            variant="outlined"
                            size="small"
                            type="password"
                            fullWidth
                          />
                          <div style={{ color: "red" }}>
                            <ErrorMessage component="span" name="senha" />
                          </div>
    
                      <Typography variant="subtitle1">Telefone</Typography>
                      <Field
                        as={Telefone}
                        name="telefone"
                        placeholder="Exemplo: (61) 9999-9999"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <div style={{ color: "red" }}>
                        <ErrorMessage component="span" name="telefone" />
                      </div>
                      <Typography variant="subtitle1">Endereço</Typography>
                      <Field
                       as={TextField}
                       name="endereco"
                       fullWidth
                       variant="outlined"
                       placeholder="Ex: SOBRADINHO, BRASÍLIA DF"
                      size="small"
                      />
                    </div>
                
                  <Divider style={{ marginTop: 10, marginBottom: 10, padding: 0 }} />
                  <CardActions
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      padding: 0
                    }}
                  >
                  
                    <Button
                      autoFocus
                      fullWidth
                      type="submit"
                      variant="outlined"
                      style={{marginTop: 10, backgroundColor:'#FFC300', color: '#ffff' }}
                    >
                      Salvar
                    </Button>
                  </CardActions>
                  <Link style={{ display: 'flex', padding:10, alignItems: 'center', justifyContent: 'flex-end' }} href="#" onClick={() => history.push('/grupo-login')}>
                     <KeyboardBackspaceIcon style={{ marginRight: 10 }} />
                        <Typography>  
                          Efetuar login
                       </Typography>
                  </Link>
                </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default FormularioCadastroGrupo;
