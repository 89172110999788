import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

import { toast } from "react-toastify";
import queryString from 'query-string';
import api from '../../services/api'
import If from "../../util/If";
import DadosCiclista from './DadosCiclista'
import runBikeGiff from "../imagens/runbike.gif";

const styles = makeStyles(() => ({
  container: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: "280px",
    padding:5
  },
  card: {
    margin: 10,
  },
  form: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 15,
  },
  rootTitulo: {
    width: "98%",
    margin: 10,
  },
}));

export function DetalharCiclista(props) {
  const { search } = window.location
  const { codMatricula } = queryString.parse(search)
  const classes = styles();
  const [matricula, setMatricula] = useState("");
  const [ciclista, setCiclista] = useState("");

  const handleConsulta = async () => {
    setMatricula(codMatricula)
    if (!matricula && !codMatricula) {
      toast.error("A matrícula é obrigatória");
      return;
    }
    try {
      const response = await api.get(`api/ciclistas/matricula/${matricula}`);
      setCiclista(response.data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const handleConsultaPorMatricula = async (matri) => {
    try {
      const response = await api.get(`api/ciclistas/matricula/${matri}`);
      setCiclista(response.data);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    codMatricula &&  handleConsultaPorMatricula(codMatricula)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
       <If test={!ciclista}>
            <div style={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
              <div className={classes.container}>
                  <Typography variant="body">INFORMAÇÕES   DO </Typography>
                  <img
                      style={{ marginTop: 25, width: '30%', height: '30%', borderRadius: '40%', margin:10 }}
                      alt="Bora Bora"
                      src={runBikeGiff}
                    />
                  <Typography variant="body">PARA EMERGÊNCIAS </Typography>
                </div>
                <div style={{ margin: 10 }}>
                      <div>
                        <TextField
                          fullWidth
                          placeholder="MATRICULA"
                          size="small"
                          focused
                          autoFocus
                          inputProps={{style: {fontSize: "20px", textAlign: 'center'}}}
                          variant="outlined"
                          type="text"
                          value={matricula}
                          onChange={(event) => setMatricula(event.target.value)}
                        />
                        <Button
                          onClick={() => handleConsulta()}
                          size="large"
                          color="primary"
                          variant="contained"
                          textSizeLarge
                          style={{marginTop: 10, backgroundColor:'#FFC300', color: '#ffff'}}
                          fullWidth
                        >
                          Consultar
                        </Button>
                      </div>
                </div>
          </div> 
       </If>
   
    <If test={ciclista}>
      <DadosCiclista ciclista={ciclista} />
    </If>
    </>
  );
}

DetalharCiclista.propTypes = {
  callbackFechaModal: PropTypes.func.isRequired,
};

export default DetalharCiclista;
