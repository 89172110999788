import {
  adicioanrTokenLocalStorage,
  limparLocalStorage,
} from "../../../util/LocalStoreUtil";

export function addToken(token) {
  adicioanrTokenLocalStorage(token);
  return {
    type: "@ADD_TOKEN",
    payload: token,
  };
}

export function removeToken() {
  limparLocalStorage();
  return {
    type: "@REMOVE_TOKEN",
    payload: "",
  };
}
