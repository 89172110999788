const usuario = JSON.parse(localStorage.getItem("usuario"));
console.log("userStore", usuario);
const INITIAL_STATE = {
  usuario: {
    nome: (usuario && usuario.nome) || null,
    administrador: usuario ? (usuario.email === 'daylansantos@gmail.com' ? true : false) : null,
    email: usuario ? usuario.email : null,
  },
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@ADD_USER":
      return {
        ...state,
        usuario: action.payload,
      };
    case "@REMOVE_USER": {
      return {
        ...state,
        usuario: "",
      };
    }
    default: {
      return state;
    }
  }
}
