import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useSelector } from "react-redux";

import { SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  icon: {
    color: "#A0C1AB",
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const auth = useSelector((state) => state.userReducer);
  const classes = useStyles();

  const isAtivoNaoAdministraor = true;

  const pages = [
    {
      title: "Portaria",
      href: "/dashboard",
      isAdministrador: isAtivoNaoAdministraor,
      icon: <DashboardIcon className={classes.icon} />,
    },
    {
      title: "Usuários",
      href: "/usuarios",
      isAdministrador: auth.usuario.administrador,
      icon: <PersonOutlineIcon className={classes.icon} />,
    },
    {
      title: "Visitantes",
      href: "/pessoas",
      isAdministrador: isAtivoNaoAdministraor,
      icon: <PeopleIcon className={classes.icon} />,
    },

    {
      title: "Moradores",
      href: "/moradores",
      isAdministrador: auth.usuario.administrador,
      icon: <PeopleIcon className={classes.icon} />,
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
