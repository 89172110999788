import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";


const styles = makeStyles((theme) => ({
    container: {
      marginTop: 20,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      justifyContent: 'center',
      flexDirection:'column',
      alignItems: 'center',
      minHeight: "280px",
    },
    card: {
      margin: 10,
    },
    form: {
      marginLeft: 20,
      marginRight: 20,
      marginBottom: 15,
    },
    rootTitulo: {
      width: "98%",
      margin: 10,
    },
  }));

const DadosCiclista = ({ ciclista }) =>  {
    const classes = styles()
    
    return (
        <div className={classes.container}>
        <div style={{ width: '100%', height: 100, display: 'flex', alignItems: 'center', paddingLeft: '20%', backgroundColor: '#eeee' }}>
            <Typography variant="subtitle1">DADOS DO CICLISTA</Typography>
        </div>
         <hr style={{ width: '100%', marginBottom: 20} } />
           <img
                style={{
                    heigth: 180,
                    width: 165,
                    borderRadius: 5,
                    marginBottom: 20,
                }}
                alt="imagem"
                src={ciclista.imagem}
                />
            <Typography variant="body1">{ciclista.matricula}</Typography>
            <Typography variant="body1">{ciclista.nome}</Typography>
            <Typography variant="body1">{ciclista.telefone}</Typography>
            <Typography variant="body1">{ciclista.email}</Typography>
            <Typography variant="body1">{ciclista.cidade || ''}</Typography>
    
            <hr style={{ width: '100%',marginTop: 20, marginBottom: 20}} />
            <Typography style={{ marginBottom: 15 }} variant="h5">CONTATO PARA EMERGÊNCIA</Typography>
            <Typography variant="body1">{ciclista.nomeEmergencia}</Typography>
            <Typography variant="body1">{ciclista.telefoneEmergencia}</Typography>
            
            <hr style={{ width: '100%',marginTop: 20}} />
            <Typography style={{ marginBottom: 15, marginTop: 20 }} variant="h6">DADOS RELACIONADOS A SAÚDE</Typography>
            <Typography variant="body1">TIPO DE SANGUE: {ciclista.tipoSangue}</Typography>
            <Typography variant="subtitle2">ALERGIAS</Typography>
            <Typography variant="body1">{ciclista.alergia || 'Não possui ou não sabe.'}</Typography>
            <Typography style={{ marginTop: 15 }} variant="subtitle2">DOENÇAS</Typography>
            <Typography variant="body1">{ciclista.doencas || 'Não possui ou não sabe.'}</Typography>
            <Typography style={{ marginTop: 15 }} variant="subtitle2">MEDICAMENTOS CONTROLADOS</Typography>
            <Typography variant="body1">{ciclista.medicamento || 'Não faz uso!'}</Typography>
            <Typography style={{ marginTop: 15 }} variant="subtitle2">TEM PLANO DE SAÚDE</Typography>
            <Typography variant="body1">{ciclista.descricaoPlano  || 'Não informado!'}</Typography>
            <div style={{ width: '100%', height: 100, display: 'flex', alignItems: 'center', paddingLeft: '20%', paddingRight: '20%', backgroundColor: '#eeee', justifyContent: 'space-between' }}>
                 {
                   ciclista.grupoDto &&
                    (<>
                      <Typography variant="subtitle1">GRUPO: {ciclista.grupoDto.nome} </Typography>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{
                              heigth: 90,
                              width: 80,
                              borderRadius: 5,
                              marginBottom: 20,
                              margin:'auto'
                          }}
                          alt="Logo do grupo não informada"
                          src={ciclista.grupoDto.logo}
                          />
                      </div>
                      </>
                   )
                  } 
                  {!(ciclista.grupoDto) && <Typography variant="subtitle1">GRUPO NÃO INFORMADO NO CADASTRO</Typography>}
                  
            </div>
            <Typography variant="subtitle1">www.identidadedociclista.com.br</Typography>
        </div>
    )
}

export default DadosCiclista