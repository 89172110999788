/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { toast } from 'react-toastify'

import api from '../../services/api'
import If from '../../util/If'
import DadosCiclista from './DadosCiclista'
import ModalConfirmacao from '../../components/ModalConfirmacao'
import ModalComponent from '../../components/ModalComponent'
import { Button, TextField, Typography } from '@material-ui/core'

const DetalharCiclistaComponent = () => {
    const [ciclista, setCiclista] = useState('')
    const [confirmacao, setConfimacao] = useState(false)
    const [openModalRegistro, setModalRisgtro] = useState(false)
    const [matricula, setMatricula] = useState(false)

    const { search } = window.location
    const { qrcode } = queryString.parse(search)


    const  handlePesquiaPorQRcode  = () => { 
      if(!qrcode) {
        return;
      }
      api.get(`/api/ciclistas/selo/${qrcode}`)
      .then((response) => {
        setCiclista(response.data)
      }).catch(err => {
        setConfimacao(true)
        console.log('error', err.response.data)
      })
    }

    useEffect(() => {
      handlePesquiaPorQRcode()
    },[qrcode])

      const handleRestraQrCode = () => {
        if(!matricula) {
          toast.error('A matrícula é obrigatória!')
          return;
        }
        api.post(`/api/ciclistas/selo/${qrcode}/matricula/${matricula}`)
        .then((response) => {
          setCiclista(response.data)
          toast.success('Ciclista vinculado com sucesso!')
          handlePesquiaPorQRcode()
          setModalRisgtro(false)
        }).catch((err => {
          toast.error(err.response.data.message || 'Falha na operação, se o problema persistir procurar o adm do sistema')
        }))
      }

      const vincularAgora =() => {
        setConfimacao(false)
        setModalRisgtro(true)
      }

    return (
      <>
        <If test={ciclista}>
          <DadosCiclista ciclista={ciclista} />
        </If>
            <ModalConfirmacao
            openConfirmacao={confirmacao}
            texto={"Não existe um ciclista cadastrado para esse QRcode deseja cadastra-lo para você?"}
            callbackConfirma={() => vincularAgora()}
            callbackFechaModal={() => setConfimacao(false)}
         >
      </ModalConfirmacao>

       <div style={{ width: '50%', minWidth: 250, maxWidth: 250, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <ModalComponent
                  titulo="VINCULAR QRCODE"
                  open={openModalRegistro}
                  callbackFechaModal={() => setModalRisgtro(false)}
            >
                <Typography>Entre com o número da sua matricula</Typography>
                <TextField style={{ marginBottom: 10 }} fullWidth size="small" variant="outlined" onChange={(event) => setMatricula(event.target.value)}/>
                <Button color="primary" fullWidth onClick={() => handleRestraQrCode()} variant="contained"  size="small">Confirma vinculo</Button>
          </ModalComponent>
         </div> 
      
      </>
    )
}

export default DetalharCiclistaComponent