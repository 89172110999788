import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Dashboard, DashBoardGerencial } from "./views";
import FormularioCadastroCiclista from "./views/Ciclista/FormularioCadastroCiclista";

import FormularioCadastroGrupo from "./views/Ciclista/FormularioCadastroGrupo";
import DetalharCiclista from "./views/Ciclista/DetalharCiclista";
import ConsultaSelos from './views/Selos/ConsultaSelos'
import Identidade from "./views/Ciclista/Identidade";
import MeuGrupo from "./views/Ciclista/MeuGrupo";
import ValidaAlteracao from './views/Ciclista/ValidaAlteracao'
import NovaSenhaGrupo from './views/Ciclista/NovaSenhaGrupo'
import FormularioEdicaoCiclista from './views/Ciclista/FormularioEdicaoCiclista'
import FormularioEdicaoGrupo from './views/Ciclista/FormularioEdicaoGrupo'
import Login from "./views/Usuario/Login";
import GeradorQRcodeComponent from './views/Dashboard/components/GeradorQRcodeComponent'
import DetalharCiclistaComponent from "./views/Ciclista/DetalharCiclistaComponent";
import NotFound from './views/NotFound/NotFound'


const Routes = () => {
  return (
    <Switch>
       <RouteWithLayout
        component={Login}
        exact
        path="/grupo-login"
      />
      <RouteWithLayout
        component={FormularioCadastroCiclista}
        exact
        path="/formulario-cadastro-ciclista"
      />
      
      <RouteWithLayout
        component={FormularioEdicaoCiclista}
        exact
        path="/formulario-edicao-ciclista"
      />
      
      
      <RouteWithLayout
        component={FormularioCadastroGrupo}
        exact
        path="/formulario-cadastro-grupo"
      />
      <RouteWithLayout
        component={DetalharCiclista}
        exact
        path="/detalhar-ciclista"
      />

      <RouteWithLayout
        component={DetalharCiclistaComponent}
        exact
        path="/perfil-online-ciclista"
      />

      <RouteWithLayout
        component={Identidade}
        exact
        path="/identidade-ciclista"
      />

      <RouteWithLayout
        component={MeuGrupo}
        exact
        path="/meu-grupo"
      />

      <RouteWithLayout
        component={ValidaAlteracao}
        exact
        path="/cadastro-ciclista-alteracao"
      />

      <RouteWithLayout
        component={NovaSenhaGrupo}
        exact
        path="/nova-senha-grupo"
      />

      <RouteWithLayout
        component={GeradorQRcodeComponent}
        exact
        path="/gerador-qr-code"
      />

    <RouteWithLayout
        component={ConsultaSelos}
        exact
        path="/consulta-selos"
      />

      <RouteWithLayout
        component={FormularioEdicaoGrupo}
        exact
        path="/cadastro-grupo-alteracao"
      />

      <RouteWithLayout
        component={DashBoardGerencial}
        exact
        path="/admin-gerencial-dashboard"
      />

      <RouteWithLayout
        component={NotFound}
        exact
        path="/not-found"
      />

      <RouteWithLayout component={Dashboard} exact path="/dashboard" />
      <RouteWithLayout component={Dashboard} exact path="/" />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
