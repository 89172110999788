import { axiosInstance as api } from "./api";

const interceptor = api.interceptors.request.use(async (config) => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  } catch (e) {
    return config;
  }
});

export default interceptor;
