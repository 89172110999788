import React from "react";
import ReactLoading from "react-loading";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import iconLoading from './imagens/loading.gif'

import { useSelector } from "react-redux";

const Loading = () => {
  const loading = useSelector((state) => state.showLoading.loading);
  return (
    <>
      <Dialog
        maxWidth="xs"
        fullWidth
        aria-labelledby="customized-dialog-title"
        open={loading}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "180",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           <img
            style={{ marginTop: 20, color:"#FFC300", height:"30%", width:"30%" }} 
            alt={"Loading"} src={iconLoading} />
          <ReactLoading type="bubbles" color="#FFC300" height="15%" width="15%" />
          <Typography style={{ marginBottom: 30 }} variant="body1">Um momento, estamos quase pronto...</Typography>
        </div>
      </Dialog>
    </>
  );
};

export default Loading;
