/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { TextField, Card,Typography, Button, Checkbox } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import TopoBar from '../../components/TopoBar'
import { Pagination } from '@material-ui/lab'
import { toast } from "react-toastify";
import  ComponetMaisOpcoes from '../../components/ComponentMaisOpcoes'
import DadosCiclista from '../Ciclista/DadosCiclista';
import ModalComponent from '../../components/ModalComponent'
import FormularioAtualizacaoSelo from './FormularioAtualizacaoSelo'

import api from "../../services/api";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    cardFiltro: {
        display: 'flex',
        marginTop: 10,
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 1200,
        alignItems: 'center',
        padding: 10
    },
    camposFiltro: {
        display: 'flex',
        alignItems: 'center'
    },
    actionPaginacao: {
        marginTop: 10,
        marginBottom: 10
    }
}))

const ConsultaSelos = () => {
    const classes  = useStyles()
    const [numeroPagina, setNumeroPagina] = useState(0)
    const [selos, setSelos] = useState([])
    const [matricula, setMatricula] = useState('')
    const [codigo, setCodigo] = useState('')
    const [ciclista, setCiclista] = useState('')
    const [selo, setSelo] = useState('')
    const [temProprietario, setTemProprietario] = useState('')

    const [state] = useState({
        paginaAtual: 0,
        tamanhoPagina: 7    
    })

    const handlePesquisaPorMatricula = (matri) => {
        if(!matri) {
            toast.error('Selo sem propriétario')
            return
        }
        api.get(`/api/ciclistas/matricula/${matri}`)
        .then((response) => {
            setCiclista(response.data)
        }).catch(err => {
            setCiclista('')
            toast.error(err.response.message)
        }) 
    };

    const handlePesquisa = (paginacao) => {
        const { paginaAtual, tamanhoPagina } = state
        const filtro = {
            pagina: (paginacao && paginacao.pagina - 1) || paginaAtual,
            tamanho: (paginacao && paginacao.size) || tamanhoPagina,
            matricula: matricula || null,
            codigo: codigo || null,
            temProprietario: temProprietario || null
        }
        api.get(`/api/selos/filtro/`,filtro)
        .then((response) => {
            setSelos(response.data.content)
            setNumeroPagina(response.data.totalPages)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }).catch((err) => {
            toast.error(err.response ? err.response.message : 'Desculpa tivemos um problema aguarde uns 20 segundos e tente novamente! ')
        })
    }

    function irParaPagina(pagina) {
        const { tamanhoPagina } = state
        const paginacao = { size: tamanhoPagina, pagina }
        handlePesquisa(paginacao)
    }

    const concluirOperacao = () => {
        setSelo('')
        handlePesquisa()
    }


    const handleLimparFiltro = () => {
        setSelos([])
        setCodigo('')
        setMatricula('')
        setNumeroPagina(0)
    }

    useEffect(() => {
        handlePesquisa()
    },[])

    return (
        <div className={classes.container}>
        <TopoBar titulo="GERECIAMENTO DE SELOS" />
            <Card className={classes.cardFiltro} >
                <div className={classes.camposFiltro}>
                    <div className={classes.camposFiltro}>
                        <TextField onChange={(event) => setCodigo(event.target.value)} label="Código" size="small" variant="outlined" />
                        <Typography style={{ marginLeft: 10, marginRight: 10 }}>OU</Typography>
                        <TextField  onChange={(event) => setMatricula(event.target.value)}  label="Matrícula" size="small" variant="outlined" />
                    </div>
                    <div className={classes.camposFiltro}>
                        <Checkbox
                            variant="outlined"
                            onChange={() =>  setTemProprietario(!temProprietario)}
                            checked={temProprietario}
                            size="small"
                            inputProps={{ 'aria-label': 'checkbox with small size' }}
                        />
                        <Typography>Somente selos com proprietários!</Typography>
                    </div>
                </div>
                <div>
                    <Button style={{ marginRight: 10 }} onClick={() => handleLimparFiltro()} variant="outlined">Limpar filtro</Button>
                    <Button style={{ color: '#ffff', backgroundColor:'#FFC300'}} onClick={() => handlePesquisa()} variant="outlined">Consultar</Button>
                </div>
            </Card>

            {
                selos.length === 0 ? 
                (
                    <Card className={classes.cardFiltro} >
                        <Typography>Não há resultado</Typography>
                    </Card>
                )
                : 
                selos.map((item) => (
                    <Card className={classes.cardFiltro} >
                        <div style={{ display: 'flex' }}>
                            <img src={item.imagem} alt="img" style={{
                                heigth: '100%',
                                width: '100%',
                                maxWidth: 70,
                                maxHeight:70,
                                borderRadius: 5,
                                border: '1px solid'
                            }}/>
                             <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                 <div style={{ marginLeft: 10,display: 'flex', marginRight: 20}}>
                                    <Typography style={{ marginRight: 10 }}>Código: {item.codigo},</Typography>
                                    <Typography style={{ marginRight: 10 }}>Adesivo foi pago: {item.pago === 'NAO' ? 'NÃO': item.pago },</Typography>
                                    <Typography>Adesivo foi impresso: {item.impresso === 'NAO' ? 'NÃO' : item.impresso }</Typography>
                                 </div>
                                 {item.matricula && (<Typography>Matrícula: {item.matricula}</Typography>) } 
                             </div>
                        </div>
                            <ComponetMaisOpcoes
                                id="id-opcoes"
                                opcoes={[
                                {
                                    label: 'Fazer atualização',
                                    callback: ()  => setSelo(item)
                                },
                                {
                                    label: 'Ver o propriétario',
                                    callback: () => handlePesquisaPorMatricula(item.matricula)                                    }
                                ]}
                            />
                    </Card>
                ))
            }

            <div className={classes.actionPaginacao}>
                <Pagination
                    count={numeroPagina}
                    variant="outlined"
                    shape="rounded"
                    onChange={(event, page) => irParaPagina(page)}
                    size="large"
                    hideNextButton
                    hidePrevButton
                />
            </div>
            <ModalComponent
                titulo="CICLISTA"
                open={ciclista}
                callbackFechaModal={() => setCiclista('')}
            >
             <DadosCiclista ciclista={ciclista} />
           </ModalComponent>

           <ModalComponent
                titulo={`ATUALIZAÇÃO DE  SELO COD ${selo && selo.codigo}`}
                open={selo}
                fullWidth={false}
                callbackFechaModal={() => setSelo('')}
            >
           <FormularioAtualizacaoSelo   callbackConcluirOperacao={() => concluirOperacao()} seloDto={selo}/>
           </ModalComponent>
        </div>
       
    )
}

export default ConsultaSelos