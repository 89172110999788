import React, {useState} from 'react'
import { Button, FormControl,Select, Typography, Card } from '@material-ui/core'
import QRCode from 'qrcode'
import { makeStyles } from "@material-ui/core/styles";
import api from '../../../services/api'
import {  toast } from 'react-toastify'
import TopoBar from '../../../components/TopoBar'
import ModalConfirmacao from "../../../components/ModalConfirmacao";


const useStyles = makeStyles( () => ({ 
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '90%',
        marginTop: 10
    },

    filtro: {
        display:'flex',
        flexDirection: 'column',
        maxWidth: 1080,
        marginTop: 5,
        width: '90  %',
        justifyContent: 'flex-start'
    },
    card: {
        display: 'flex',
        flexWrap: 'wrap',
         width:'80%', maxWidth: 1050, heigth: '100%', maxHeight: 500 
    },
    ItemCard: {
        display:'flex',
        padding: 10,
        heigth: 130,
        margin: 20,
        minHeight: 130,
        marginTop: 20,
        maxWidth: 185,
        justifyContent: 'center',
        alignItems:'center',
    },
    table: {
        height: '50%'
    },
    actionPaginacao: {
        marginTop: 5
    }
}))


const GeradorQRcodeComponent = () => {
    const classes = useStyles()
    const [selos, setSelos] = useState([])
    const [confirmacao, setConfirmacao] = useState(false)
    const [qtd, setQtd] = useState(0)

    const handleGeraSelo  = async (outros) => {
        let novosSelos = []
        for(var i = 0; i < outros.length; i ++) {
            var item = outros[i]
            const imagem = await QRCode.toDataURL(`http://www.identidadedociclista.com.br/perfil-online-ciclista?qrcode=${item.codigo}`);
            item.imagem = imagem
            novosSelos = [...novosSelos, item]
        }
        setSelos(novosSelos)
        window.scrollTo({ bottom: 0, left: 0, behavior: 'smooth' })
    }

    const handlePesquisaObjetoGeracao = async  () => {
        if (!qtd) {
            toast.error('Você precisa escolhar quantos quer gerar')
            return;
        }
        try {
            const response  = await api.get(`/api/selos/gerar/${qtd}`)
            console.log('response',response.data)
            setSelos(response.data)
            handleGeraSelo(response.data)
            setConfirmacao(true)
        } catch (err) {
            toast.error(` Ouve um erro ${err.response ? err.response.message : 'na operação!'}`)
        }
    }

    function convertBase64ToBlob (base64, contentType) {
        let byteCharacters = window.atob(base64)
        let sliceSize = base64.length
        let byteArrays = []
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize)
          let byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }
      
          let byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
      
        return new Blob(byteArrays, { type: contentType })
      }

    function gerarDocumento (documento, id, nomeDocumento) {
        let blob = convertBase64ToBlob(documento.data, 'application/pdf')
        let link = document.createElement('a')
        link.id = id
        link.href = window.URL.createObjectURL(blob)
        link.download = nomeDocumento
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }



    const handleGerarDocumentoImpressao = async  () => {
        if (!qtd) {
            toast.error('Você precisa escolhar quantos quer gerar')
            return;
        }
        try {
            const response  = await api.get(`/api/selos/gerar/relatorio/${qtd}`)
            console.log('response',response)
            gerarDocumento(response, '',` documentos com ${qtd} selos`)
        } catch (err) {
            toast.error(`${err ? err.response.data.message : 'Falha na operação!'}`)
        }
    }

    const handleSalvarSelos  = () => {
        api.post(`/api/selos/atualizar`, {
            selos
        })
        .then((response) => {
            toast.warn(`Selos salvo com sucesso!`)
            setConfirmacao(false)
        }).catch(err => {
            toast.error(`Erro ao  salvar selos`)
        })
    }

    const handleCancelar  = () => {
        api.post(`/api/selos/cancelar`, {
            selos
        })
        .then((response) => {
            toast.warn(`Os selos não seram salvos`)
            setConfirmacao(false)
        }).catch(err => {
            toast.error(`Erro ao  cancelar selos`)
        })
    }

    return(
        <div className={classes.container}>
            <TopoBar titulo="CONFIGURAÇÕES PARA GERAR SELOS"  rota="consulta-selos" labelRota="GERECIAMENTO DE SELOS"/>
           <div className={classes.filtro}>
             <div>
             <FormControl variant="outlined" size="small" style={{ marginRight: 10 }} >
                <Select
                native
                value={qtd}
                onChange={(event) => setQtd(event.target.value)}
                inputProps={{
                    name: 'age',
                    id: 'age-native-simple',
                }}
                >
                    <option aria-label="None" value=""> Selecione quantos você quer </option>
                    <option value={10}>Quero 10 </option>
                    <option value={50}>Quero 50</option>
                    <option value={100}>Quero 100</option>
                </Select>
              </FormControl>
             <Button variant="outlined" onClick={() => handlePesquisaObjetoGeracao()}>GERAR QRCODES</Button>
             <Button style={{ marginLeft: 10 }} variant="outlined" onClick={() => handleGerarDocumentoImpressao()}>GERAR IMPRESSÃO QR-CODE</Button>
             </div>
            </div>
        
            <div className={classes.card}>
            {
                selos.length > 0 && selos.map(it => (
                        <Card className={classes.ItemCard}>
                                <div style={{ display: 'flex' }} key={it.codigo}>
                                    <a href={it.imagem} download>
                                        <img src={it.imagem} alt="img" style={{
                                            heigth: '100%',
                                            width: '100%',
                                            maxWidth: 200,
                                            maxHeight:100,
                                            borderRadius: 5,
                                            border: '1px solid'
                                        }}/>
                                    </a>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                     <Typography variant="body2">CODIGO {it.codigo}</Typography>
                                    </div>
                                </div>  
                        </Card>
                ))
            }
            </div>
            <ModalConfirmacao
                openConfirmacao={confirmacao}
                texto={"Selos foi gerados com sucesso, deseja salva-los na base de dados?"}
                callbackConfirma={() => handleSalvarSelos()}
                callbackFechaModal={() => handleCancelar()}
             >

          </ModalConfirmacao>
        </div>
    ) 
}

export default GeradorQRcodeComponent