const INITIAL_STATE = {
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@ADD_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "@REMOVE_LOADING": {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
