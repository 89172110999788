import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Link } from '@material-ui/core'

export default function TopoBar( {  titulo, rota, labelRota }) {
    const history = useHistory()
    return (
        <div style={{ 
            justifyContent:'space-between',
            width: '100%',
            height: 100,
            minHeight: 100,
            marginTop: 20,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20%',
            paddingRight: '20%',
            backgroundColor: '#eeee'
        }}>
        <Typography variant="subtitle1">{titulo}</Typography>
        {rota && <Link onClick={() => history.push(rota)} variant="subtitle1">{labelRota}</Link> }
        
    </div>
    )
} 