/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState,useEffect } from 'react'
import {  useHistory } from 'react-router-dom'
import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card';
import { Pagination } from '@material-ui/lab'

import { toast } from "react-toastify";

import api from "../../services/api";
import { gerarDocumento } from '../../util/geraDocumentoPdf'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PortraitIcon from '@material-ui/icons/Portrait';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DadosCiclista from './DadosCiclista';
import ModalComponent from '../../components/ModalComponent'



const useStyles = makeStyles( () => ({ 
    continer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '90%'
    },
    card: {
        display:'flex',
        padding: 10,
        margin: 20,
        flexDirection: 'column',
        marginTop: 20,
        maxWidth: 1080,
        justifyContent: 'center',
        alignItems:'center',
        width:'100%',
    },
    ItemCard: {
        display:'flex',
        padding: 10,
        margin: 20,
        flexDirection: 'column',
        marginTop: 20,
        maxWidth: 1080,
        width:'100%',
    },
    table: {
        height: '50%'
    },
    actionPaginacao: {
        marginTop: 5
    }
}))
  

const MeuGrupo = () => {
    const [numeroPagina, setNumeroPagina] = useState(0)
    const [totalRegistro, setTotalRegistro] = useState(0)
    const history = useHistory()
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const classes = useStyles()
    const [ciclistas, setCiclistas] = useState([])
    const [numMatricula, setNumMatricula] = useState('')
    const [nome, setNome] = useState('')
    const [ciclista, setCiclista] = useState('')

    const [state] = useState({
        paginaAtual: 0,
        tamanhoPagina: 10
      })

    const handlePesquisa = (paginacao) => {
        const { paginaAtual, tamanhoPagina } = state
        const filtro = {
            pagina: (paginacao && paginacao.pagina - 1) || paginaAtual,
            tamanho: (paginacao && paginacao.size) || tamanhoPagina,
          }
        api.get(`/api/grupos/email/${usuario.email}/ciclistas`,filtro)
        .then((response) => {
            setCiclistas(response.data.content)
            setNumeroPagina(response.data.totalPages)
            setTotalRegistro(response.data.totalElements)
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }).catch((err) => {
            toast.error(err.response ? err.response.message : 'Desculpa tivemos um problema aguarde uns 20 segundos e tente novamente! ')
        })
    }

    const handlePesquisaGrupo = async (cicli) => {
        if(!cicli.idGrupo) {
            setCiclista(cicli)
            return
        }
        try {
        const response = await api.get(`/api/grupos/id/${cicli.idGrupo && cicli.idGrupo}`)
         if(response.data) {
            cicli.grupoDto = response.data
            setCiclista(cicli)
         }
       } catch(err) {
        console.log(err)
       }
    }

    const handleGerarIdentidadeCiclista = (async (matricula) => {
        api.get(`/api/ciclistas/gerar-id/${matricula}`)
        .then((response) => {
            handleGerarIdentidade(response, matricula)
          }).catch(err => {
            console.log(err)
          }) 
    });
    

    const isAutorizadoUpdate = () => {
        return usuario.email === 'daylansantos@gmail.com' || usuario.email === 'radicalbike10@hotmail.com'
    }
    
    const handleConsulta  = () => {
        if(nome) {
            handlePesquisaPorNome()
            setNumMatricula('')
            return
        }
        
        if(numMatricula){
            handlePesquisaPorMatricula()
            return
        }
        handlePesquisa()
    }

    const handlePesquisaPorMatricula = () => {
        api.get(`/api/ciclistas/matricula/${numMatricula}`)
        .then((response) => {
            setCiclistas([response.data])
        }).catch(err => {
            setCiclistas([])
            toast.error(err.response.message)
        }) 
    };
    const handlePesquisaPorNome = () => {
        api.get(`/api/ciclistas/nome/${nome}`)
        .then((response) => {
            setCiclistas(response.data)
        }).catch(err => {
            setCiclistas([])
            toast.error(err.response.message)
        }) 
    };
    
    function irParaPagina(pagina) {
        const { tamanhoPagina } = state
        const paginacao = { size: tamanhoPagina, pagina }
        handlePesquisa(paginacao)
    }

    useEffect(() => {
        handlePesquisa()
    }, [])

    const handleGerarIdentidade = async (identidade, matricula) => {
        let response = ''
          response = await api.get(`/api/ciclistas/matricula/${matricula}`)
        gerarDocumento(identidade, '10',`IDENTIDADE-CICLISTA- ${response.data.nome}`)
        toast.info(`A carteirinha do ${response.data.nome} foi gerada com sucesso!`)
    }

    return (
        <div className={classes.continer}>
                <div style={{ 
                        justifyContent:'space-between',
                        width: '100%',
                        height: 100,
                        minHeight: 100,
                        marginTop: 20,
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '20%',
                        paddingRight: '20%',
                        backgroundColor: '#eeee'
                    }}>
                    <Typography variant="subtitle1">INTEGRANTES DO MEU GRUPO</Typography>
                    <Link  onClick={() => history.push(`cadastro-grupo-alteracao?email=${usuario.email}`)} >ALTERAR MEU CADASTRO</Link>
                </div>
              <div className={classes.card}>
                <div style={{ width: '100%', margin: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body">Pesquisa por nome ou pela matrícula</Typography>
                        <Typography variant="body">Total de ciclistas para seu grupo {totalRegistro}</Typography>
                    </div>
    
                    <TextField style={{ marginTop: 10 }} value={nome}  onChange={(event) => setNome(event.target.value)} label="Nome" placeholder="Entre com parte do nome" size="small" variant="outlined" fullWidth />
                    <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-between' }}>
                        <TextField value={numMatricula} onChange={(event) => setNumMatricula(event.target.value)} label="Matrícula" placeholder="Entre com a matrícula" size="small" variant="outlined" />
                        <Button onClick={() => handleConsulta()} style={{marginLeft: 10, backgroundColor:'#FFC300', color: '#ffff' }} size="small" variant="contained">Pesquisar</Button>
                    </div>   
                </div>

                <div style={{ display: 'flex', flexDirection:'column', width: '100%', alignItems: 'center' }}>
                    {ciclistas.length > 0 && ciclistas.map((row) => (
                        <Card className={classes.ItemCard}>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'flex-start' }}>
                            <img
                                style={{
                                heigth: '100%',
                                width: '100%',
                                maxWidth: 60,
                                maxHeight:60,
                                borderRadius: 50,
                                border: '1px solid yellow'
                                }}
                                alt="imagem"
                                src={row.imagem}
                            />
                            <div style={{ marginLeft: 10 }}>
                                <Typography>
                                    {` Matrícula: ${row.matricula}`}
                                </Typography>
                                <div style={{ display:'flex', width: '100%'}}>
                                    <Typography >Telefone: {row.telefone}</Typography>
                                     <Typography style={{ marginLeft: 20 }} >E-mail: {row.email}</Typography>
                                </div>
                            </div>
                            </div>

                            <div style={{ display:'flex', width: '100%', justifyContent:'space-between' }} >
                               <Typography variant="h6">
                                    {`${row.nome}  ${row.cidade ? `(${row.cidade})` : '' }` }
                                </Typography>
                               
                                <div style={{ display: 'flex', width:'50%', justifyContent:'flex-end'  }}>
                                        <Button>
                                        <PortraitIcon onClick={() => handlePesquisaGrupo(row)}></PortraitIcon>
                                        </Button>
                                        {isAutorizadoUpdate() &&   <Button><BorderColorIcon onClick={() => history.push(`/formulario-edicao-ciclista?codMatricula=${row.matricula}`)}></BorderColorIcon> </Button>}
                                        <Button>
                                        <PictureAsPdfIcon onClick={() => handleGerarIdentidadeCiclista(row.matricula)}/>
                                        </Button>
                                </div>
                            </div>
                        </Card >
                    ))}
                </div >
            
            <div className={classes.actionPaginacao}>
            <Pagination
                count={numeroPagina}
                variant="outlined"
                shape="rounded"
                onChange={(event, page) => irParaPagina(page)}
                size="large"
                hideNextButton
                hidePrevButton
            />
            </div>
          </div>
          <ModalComponent
                titulo="CICLISTA"
                open={ciclista}
                callbackFechaModal={() => setCiclista('')}
            >
            <DadosCiclista ciclista={ciclista} />
        </ModalComponent>
        </div>
    )
}

export default MeuGrupo