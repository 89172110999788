import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Main as MainLayout, Minimal as MinimalLayout } from "../../layouts";

const RouteWithLayout = (props) => {
  const { isNotFount, isPrivate, component: Component, ...rest } = props;

  const auth = useSelector((state) => state.auth);

  const { isAutenticado } = auth;

  const isUsuarioLogado = isAutenticado;

  const Layout = isUsuarioLogado ? MainLayout : MinimalLayout;

  /**
   *  if (!isUsuarioLogado && isPrivate) {
    return <Redirect to="/" />;
  }
  if (isUsuarioLogado && !isPrivate) {
    return <Redirect to="dashboard" />;
  }
   */

  return (
    <Route
      {...rest}
      render={(children) => (
        <Layout>
          <Component {...children} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
