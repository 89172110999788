export function adicioanrTokenLocalStorage(token) {
  localStorage.setItem("token", token);
}

export function adicionarUsuarioStorage(usuario) {
  localStorage.setItem("usuario", JSON.stringify(usuario));
}

export function limparLocalStorage() {
  localStorage.clear();
}
