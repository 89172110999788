import { useState, useEffect } from "react";

export const TEMPO_DEBOUNCE_RAPIDO = 350;
export const TEMPO_DEBOUNCE_PADRAO = 500;

// Mais informações: https://usehooks.com/useDebounce/
export const useDebounce = (value, delay = TEMPO_DEBOUNCE_PADRAO) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
