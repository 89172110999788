import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 10,
    marginLeft: 10,
    borderRadius: "50%",
  },
}));

const Topbar = (props) => {
  const { className, ...rest } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleRedirect = (url) => {
    history.push(url);
  };
  return (
    <AppBar
      style={{
        background: "#FFC300",
        display: "flex",
        height:64,
        flexDirection: "row",
        cursor: "pointer",
      }}
      {...rest}
      className={clsx(classes.root, className)}
      color="secondary"
      position="fixed"
      onClick={() => handleRedirect("/")}
    >
      <img className={classes.image} alt="Logo" src="/images/logos/logo.png" />
      <Toolbar>
        <h3 style={{ marginRight: 10 }}>IDENTIDADE DO CICLISTA </h3>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
