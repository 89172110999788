import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, Hidden } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import ModalComponent from "../../../../components/ModalComponent";
import SobreSistema from "./SobreSistema";
import { removeToken } from "../../../../store/modules/auth/action";
import { addUser } from "../../../../store/modules/usuario/action";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  image: {
    height: 80,
    width: 80,
    marginRight: 10,
    borderRadius: "50%",
  },
  titulo: {
    marginRight: 10,
  },
}));

const Topbar = (props) => {
  const auth = useSelector((state) => state.userReducer);
  const { usuario } = auth;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openSobre, setOpenSobre] = useState(false);

  const [openMenu, setOpenMenu] = React.useState(null);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleClose = () => {
    setOpenMenu(null);
  };

  const handleLogout = () => {
    dispatch(removeToken());
    dispatch(addUser({nome: null}));
    setOpenMenu(null);
  };

  return (
    <AppBar style={{
       background: "#FFC300",
       display: "flex",
       height:64,
       }}>
      <Toolbar  onClick={() => history.push("/")}>
        <img
          className={classes.image}
          alt="Logo"
          src="/images/logos/logo.png"
        />
        <h3 className={classes.titulo}>IDENTIDADE DO CICLISTA</h3>
        <Button onClick={handleLogout}>Sair</Button>
        <div className={classes.flexGrow} />
        <Hidden>
          {usuario && `BEM VINDO: ${usuario.nome}`}
          <Button style={{ color: "#ffff" }} onClick={handleClick}>
            <MoreVertIcon color="inherit" />
          </Button>
          <div>
            <Menu
              id="simple-menu"
              anchorEl={openMenu}
              keepMounted
              open={Boolean(openMenu)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
              <MenuItem onClick={() => setOpenSobre(true)}>Sobre</MenuItem>
            </Menu>
          </div>
        </Hidden>
       
      </Toolbar>
      <ModalComponent
        titulo="INFORMAÇÕES DO SISTEMA"
        open={openSobre}
        callbackFechaModal={() => setOpenSobre(false)}
      >
        <SobreSistema />
      </ModalComponent>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
