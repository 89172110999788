import React, { useState, useEffect }  from 'react'
import { TextField, Button,Checkbox } from '@material-ui/core'
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import { toast } from "react-toastify";

import api from "../../services/api";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: 500,
        padding: 10
    },
    containerCheck: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}))

const FormularioAtualizacaoSelo = ({ seloDto, callbackConcluirOperacao }) => {
    const classes = useStyles()
    const [isImpresso, setIsImpresso] = useState('')
    const [isPago, setIsPago] = useState('NAO')
    const [matricula, setMatricula] = useState('')

    const handleUpdate = () => {
        const selo = {
            codigo: seloDto.codigo,
            matricula: matricula,
            impresso: isImpresso,
            pago:isPago
        }
        api.post('/api/selos',selo)
        .then(() => {
            toast.success('Operação efetua com sucesso')
            callbackConcluirOperacao()
        }).catch((err) => {
            toast.error('Ocorreu um erro, se o problema continuar procure o administrador do sistema')
        })
    }


    useEffect(() => {
        setMatricula(seloDto.matricula)
        setIsImpresso(seloDto.impresso)
        setIsPago(seloDto.pago)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (
        <div className={classes.container}>
            <TextField value={seloDto.codigo} disabled variant="outlined"  fullWidth label="Código do selo" />
            <TextField style={{ marginTop: 10 }} value={matricula} onChange={(evet) => setMatricula(evet.target.value)} variant="outlined" fullWidth label="Matrícula" />
            <div className={classes.containerCheck}>
                <Checkbox
                    variant="outlined"
                    checked={isPago === 'SIM'}
                    defaultChecked={isPago === 'SIM'}
                    onChange={() =>  setIsPago(isPago === 'SIM' ? 'NAO' : 'SIM')}
                    size="small"
                    inputProps={{ 'aria-label': 'checkbox with small size' }}
                />
                <Typography>Foi Pago</Typography>
            </div>
            <div className={classes.containerCheck}>
             
                <Checkbox
                    variant="outlined"
                    onChange={() =>  setIsImpresso(isImpresso === 'SIM' ? 'NAO' : 'SIM')}
                    checked={isImpresso === 'SIM'}
                    defaultChecked={isImpresso === 'SIM'}
                    size="small"
                    inputProps={{ 'aria-label': 'checkbox with small size' }}
                />
                <Typography>Foi Impresso</Typography>
            </div>
            <Button onClick={() => handleUpdate()} variant="outlined" >Aplica alteração</Button>
        </div>
    )
}

export default FormularioAtualizacaoSelo