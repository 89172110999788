import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import http from "../services/api";
import { useDebounce } from "../util/CustomUtilHooks";

const AsyncSelectBasico = (props) => {
  const {
    label,
    propriedadeItemLabel,
    urlBusca,
    isDesativado,
    labelCarregando,
    labelSemOpcoes,
    milisegundosDebounce,
    valor,
    setValor,
    placeholder
  } = props;

  const [resultadosBusca, setResultadosBusca] = useState([]);
  const [valorInput, setValorInput] = useState("");
  const [isBuscando, setIsBuscando] = useState(false);
  const valorInputDebounced = useDebounce(valorInput, milisegundosDebounce);

  const idComponente = `Async-Autocomplete-${label}`;

  const realizarBusca = (valorComplementar) => {
    setIsBuscando(true);

    http
      .get(`${urlBusca}${valorComplementar}`, "", false)
      .then((respostaItens) => setResultadosBusca(respostaItens.data))
      .catch(() =>
        console.log(
          `Não foi possível buscar ${valorComplementar} em ${urlBusca}`
        )
      )
      .finally(() => setIsBuscando(false));
  };

  useEffect(() => {
    if (valorInputDebounced.length >= 3) {
      realizarBusca(valorInputDebounced);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valorInputDebounced]);

  const handleInputChange = (evento, value, razao) => {
    if (razao === "reset" || razao === "clear") {
      setResultadosBusca([]);
    }

    if (razao === "clear") {
      setValor(evento, value);
    }
  };

  const handleChange = (evento) => {
    setValorInput(evento.target.value);
  };

  const renderInputTextField = (params) => (
    <TextField
      {...params}
      label={label}
      variant="outlined"
      fullWidth
      size="small"
      onChange={handleChange}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isBuscando ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      as={TextField}
      id={idComponente}
      autoComplete
      autoHighlight
      placeholder={placeholder}
      value={valor}
      onChange={setValor}
      onInputChange={handleInputChange}
      disabled={isDesativado}
      getOptionLabel={(item) => item[propriedadeItemLabel]}
      options={resultadosBusca}
      loading={isBuscando}
      loadingText={labelCarregando}
      noOptionsText={labelSemOpcoes}
      renderInput={renderInputTextField}
    />
  );
};

AsyncSelectBasico.defaultProps = {
  labelCarregando: "Buscando...",
  labelSemOpcoes: "Sem opções...",
  milisegundosDebounce: 500,
  isDesativado: false,
  valor: null,
};

AsyncSelectBasico.propTypes = {
  // Label que será utilizada na concatenação do id do componente
  // e na label do componente TextField interno.
  label: PropTypes.string.isRequired,

  // Qual propriedade no objeto de 'item' será mostrada
  // ao selecionar um item.
  propriedadeItemLabel: PropTypes.string.isRequired,

  // Qual a url de busca feita pelo componente
  // OBS: O componente faz uma concatenação do texto desejado
  //  na url de busca, ou seja, verifique se a url desejada faz a
  //  busca desta forma.
  // Exemplo: api/nomes/
  // Exemplo durante execução (Componente adiciona 'textoBusca'): api/nomes/textoBusca
  urlBusca: PropTypes.string.isRequired,

  // Necessário ser do tipo 'any' para permitir a flexibilidade
  // dos dados gerenciados.
  // OBS: null indica que o item é 'vazio' ou 'não selecionado'
  // eslint-disable-next-line react/forbid-prop-types
  valor: PropTypes.any,

  // Função que altera o valor de um estado no componente pai.
  // Exemplo: (evento, valor) => setStateNoPai(valor)
  setValor: PropTypes.func.isRequired,

  // Número em milisegundos que impede alterações no input
  // durante este período, como uma função de debounce faz.
  // Padrão: 500ms
  milisegundosDebounce: PropTypes.number,

  labelCarregando: PropTypes.string,
  labelSemOpcoes: PropTypes.string,
  isDesativado: PropTypes.bool,
};

export default AsyncSelectBasico;

export const AsyncSelectBasicoFormik = ({ field, form, ...props }) => (
  <AsyncSelectBasico
    field={field}
    errors={form.errors}
    valor={form.values[field.name]}
    {...props}
    setValor={(evento, value) => form.setFieldValue(field.name, value || "")}
  />
);
