import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const ModalConfirmacao = props => {
  const {
    openConfirmacao,
    texto,
    callbackConfirma,
    callbackFechaModal
  } = props;

  const handleConfirma = () => {
    callbackConfirma();
  };

  return (
    <div>
      <Dialog
          open={openConfirmacao}
          onClose={callbackFechaModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirmação'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {texto}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={callbackFechaModal} color="primary">
            Não
          </Button>
          <Button onClick={handleConfirma} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalConfirmacao.propTypes = {
  callbackConfirma: PropTypes.func.isRequired,
  callbackFechaModal: PropTypes.func.isRequired,
  texto: PropTypes.string.isRequired,
  openConfirmacao: PropTypes.bool.isRequired
};

export default ModalConfirmacao;
