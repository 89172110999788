import React, { useState } from "react";
import {  useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Divider from '@material-ui/core/Divider';
import { Card, Typography } from "@material-ui/core";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import BuildIcon from '@material-ui/icons/Build';

import ciclistaIcon from "../imagens/ciclistaIcon.png";
import identidade from "../imagens/identidade.png";
import loopa from "../imagens/loopa.png";
import grupo from "../imagens/grupo.png";
import mixirica from '../imagens/mixirica.jpeg'
import radicalbikes from '../imagens/RadicalBike.jpg'
import topConstrucao from '../imagens/top.jpeg'
import alteraCadastro from '../imagens/alteraCadastro.png'
import tvnBrasil from '../imagens/tvnBrasil.jpg'

//grupos
import JaguarDoPedal from '../imagens/grupos/JAGUARDOPEDAL.jpeg'
import NightBike from '../imagens/grupos/NIGHT BIKE.jpeg'
import PedalCorDeRosa from '../imagens/grupos/PEDALCORDEROSA.jpeg'
import zumbas from '../imagens/grupos/ZUMBAS.png'
import inludidos from '../imagens/grupos/inludidos.jpeg'
import PedalaMangueral from '../imagens/grupos/PedalaManderal.jpeg'
import PedalMulambo from '../imagens/grupos/PedalMulambo.jpeg'
import edna from '../imagens/grupos/edna.png'

import Criador from '../imagens/dailanbueno.jpeg'
import Logo from '../imagens/idCiclista.png'

import If from '../../util/If'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: 'wrap',
    width: '100%',
    padding:15
  },

  containerCard: {
    display: "flex",
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
  },

  apoio: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: 'wrap',
    marginTop: 10,
    padding: 15
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
    height: 140,
    margin: 13,
    borderRadius: 40,
    cursor: "pointer",
  },
  cardHover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
    height: 140,
    margin: 13,
    borderRadius: 40,
    backgroundColor: "#EFB766",
    cursor: "pointer",
  },
  icon: {
    width: 35,
    height: 35,
  },
  iconApoio: {
    marginTop:10,
    paddingRight: 10,
    width: 160,
    height: 140,
    borderRadius: '50%'
  },

  iconCriador: {
    marginTop:10,
    paddingRight: 10,
    width: 100,
    height: 80,
    borderRadius: '50%'
  },

  iconGrupo: {
    marginTop:10,
    paddingRight: 10,
    width: 110,
    height: 90,
    borderRadius: '50%'
  },

  cardGrupo: {
    display: 'flex',
    flexDirection:'column',
    margin:10,
    justifyContent: 'center',
    alignItems: 'center'
  },

  iconApoioHover: {
    marginTop:10,
    paddingRight: 10,
    width: 170,
    height: 150,
    borderRadius: 20,
    cursor: "pointer",
  },
  dividi: {
    height: 2
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const [hoverCad, setHoverCad] = useState(false);
  const [hoverFind, setHOverFind] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [hoverGrupo, setHoverGrupo] = useState(false);
  const [hoveAlterar, setHoveAlterar] = useState(false);
  const [hoverSelo, setHoverSelo] = useState(false);

  const [hoverRadical, setHoverRadical] = useState(false);
  const [hoverMixirica, setHoverMixirica] = useState(false);
  const [hoverTvnBrasil, setHoverTvnBrasil] = useState(false);
  const [hoverTopConstrucao, setHoverTopConstrucao] = useState(false);
  
  const auth = useSelector((state) => state.userReducer);
  const { usuario } = auth

  const handleRedirect = (url) => {
    history.push(url);
  }; 

  const link = (linkHttp) => {
    window.open(linkHttp, '_blank')
  }


  return (
    <div>
       <div className={classes.root}>
      <div className={classes.containerCard}>
        <Card
          onClick={() => handleRedirect("formulario-cadastro-ciclista")}
          className={hoverCad ? classes.cardHover : classes.card}
          onMouseEnter={() => setHoverCad(true)}
          onMouseLeave={() => setHoverCad(false)}
        >
          <img
            className={classes.icon}
            alt="IDENTIDADE DO CICLISTA"
            src={ciclistaIcon}
          />
          <Typography variant="caption">Cadastro do ciclista</Typography>
        </Card>
        <Card
          onClick={() => handleRedirect("detalhar-ciclista")}
          className={hoverFind ? classes.cardHover : classes.card}
          onMouseEnter={() => setHOverFind(true)}
          onMouseLeave={() => setHOverFind(false)}
        >
          <img className={classes.icon} alt="CONSULTA" src={loopa} />
          <Typography variant="caption">Consultar</Typography>
        </Card>

        <Card
          onClick={() => handleRedirect("identidade-ciclista")}
          className={hoverId ? classes.cardHover : classes.card}
          onMouseEnter={() => setHoverId(true)}
          onMouseLeave={() => setHoverId(false)}
        >
          <img
            className={classes.icon}
            alt="GERAR IDENTIDADE"
            src={identidade}
          />
          <Typography variant="caption">Gerar Identidade </Typography>
        </Card>
      </div>

      <If test={usuario.nome == null}>
          <div className={classes.containerCard}>
            <Card
                onClick={() => handleRedirect("cadastro-ciclista-alteracao")}
                className={hoveAlterar ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoveAlterar(true)}
                onMouseLeave={() => setHoveAlterar(false)}
              >
                <img className={classes.icon} alt="Altera" src={alteraCadastro} />
                <Typography variant="caption">Alterar meu cadastro</Typography>
            </Card>

            <Card
              onClick={() => handleRedirect("grupo-login")}
              className={hoverGrupo ? classes.cardHover : classes.card}
              onMouseEnter={() => setHoverGrupo(true)}
              onMouseLeave={() => setHoverGrupo(false)}
            >
              <img className={classes.icon} alt="GRUPOS" src={grupo} />
              <Typography variant="caption">Grupos</Typography>
            </Card>
          </div>
      </If>

      <If test={usuario.nome != null}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Card
                onClick={() => handleRedirect("cadastro-ciclista-alteracao")}
                className={hoveAlterar ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoveAlterar(true)}
                onMouseLeave={() => setHoveAlterar(false)}
              >
                <img className={classes.icon} alt="Altera" src={alteraCadastro} />
                <Typography variant="caption">Alterar meu cadastro</Typography>
            </Card>


            <Card
              onClick={() => handleRedirect("meu-grupo")}
              className={hoverSelo ? classes.cardHover : classes.card}
              onMouseEnter={() => setHoverSelo(true)}
              onMouseLeave={() => setHoverSelo(false)}
            >
              <img className={classes.icon} alt="Integrantes do meu grupo." src={grupo} />
              <Typography variant="body1">Meu grupo</Typography>
            </Card>
            <If test={usuario.administrador}>
              <Card
                onClick={() => handleRedirect("admin-gerencial-dashboard")}
                className={hoverGrupo ? classes.cardHover : classes.card}
                onMouseEnter={() => setHoverGrupo(true)}
                onMouseLeave={() => setHoverGrupo(false)}
              >
                <BuildIcon fontSize="large" />
                <Typography variant="caption">Gereciamento</Typography>
              </Card>
            </If>
          </div>
      </If>
    </div>
    <Divider className={ classes.dividi} />

    <div className={classes.apoio}>
    <Typography style={{ marginBottom: 0, fontSize: 15 }} Typography variant="body">Sobre o desenvolvedor</Typography>
    <img 
        className={classes.iconCriador}
     alt="Dailan Bueno" src={Criador} />
     <Typography variant="subtitle1">
       Dailan Bueno
     </Typography>
     <Typography variant="body">
        Desenvolvedor de software  e apaixonado por pedalar 
     </Typography>
     <Link style={{ display:'flex', alignItems:'center' }} onClick={() => link('https://www.linkedin.com/in/dailan-bueno-b0b955b7/')}>  <LinkedInIcon/> saber mais</Link>
    </div>

    <Divider className={ classes.dividi} />

    <div className={classes.apoio}>
           <Typography style={{ marginBottom: 0}} Typography variant="body">Apoio</Typography>
            <div className={classes.containerCard}>
              <img  
                 onMouseEnter={() => setHoverRadical(true)}
                 onMouseLeave={() => setHoverRadical(false)}
                 className={ hoverRadical ?  classes.iconApoioHover : classes.iconApoio}
                 onClick={() => link('https://www.facebook.com/radicalbikesob/')}  alt="RADICAL BIKE." src={radicalbikes} />
              <img 
                  onMouseEnter={() => setHoverMixirica(true)}
                  onMouseLeave={() => setHoverMixirica(false)}
                  className={ hoverMixirica ?  classes.iconApoioHover : classes.iconApoio}
              alt="MIXIRICA." src={mixirica} />
              <img 
                  onMouseEnter={() => setHoverTvnBrasil(true)}
                  onMouseLeave={() => setHoverTvnBrasil(false)}
                  className={ hoverTvnBrasil ?  classes.iconApoioHover : classes.iconApoio}
                  onClick={() => link('http://www.tvnbrasil.com.br/')} 
              alt="TVN BRASIL" src={tvnBrasil} />

              <img 
                onMouseEnter={() => setHoverTopConstrucao(true)}
                onMouseLeave={() => setHoverTopConstrucao(false)}
                className={ hoverTopConstrucao ?  classes.iconApoioHover : classes.iconApoio}
              onClick={() => link('https://www.topdaconstrucao.com.br')} alt="TOP DA CONSTRUÇÃO" src={topConstrucao} />
            </div>  
    </div>

    <Divider className={ classes.dividi} />
    <div className={classes.apoio}>
          <Typography style={{ marginBottom: 0}} Typography variant="body">Veja alguns grupos que estão cadastrados no Identidade do Ciclista</Typography>
            <div className={classes.containerCard}>
              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"PEDAL COR DE ROSA"} src={PedalCorDeRosa} />
                  <div>Sobradinho - DF</div>
              </div>      
              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"JAGUAR DO PEDAL"} src={JaguarDoPedal} />
                  <div>Sobradinho - DF</div>
              </div>     

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"NIGHT BIKE"} src={NightBike} />
                  <div>NIGHT BIKE - UNAÍ MG</div>
              </div>     

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"ZUMBA`S do Pedal"} src={zumbas} />
                  <div>N. Bandeirante - DF</div>
              </div>     

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"RADICAL BIKE"} src={radicalbikes} />
                  <div>Sobradinho - DF</div>
              </div>    

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"ILUDIDOS"} src={inludidos} />
                  <div>Três Coroas - RS</div>
              </div>   

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"PEDALA MANGUERAL"} src={PedalaMangueral} />
                  <div>Jardins Mangueiral, DF</div>
              </div>   

              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"PEDAL MULAMBO"} src={PedalMulambo} />
                  <div>Planaltina-DF</div>
              </div>   
              <div className={classes.cardGrupo}>
                <img
                  className={classes.iconGrupo}
                  alt={"EDNA LUIZA CYCLING"} src={edna} />
                  <div>Valparaíso-GO</div>
              </div>   

            </div> 
    </div>

    <Divider className={ classes.dividi} />
    <div style={{ flexWrap: 'wrap', display: 'flex', justifyContent: 'space-between', margin: 10 }}>
      <div style={{ display: 'flex', flexDirection:'row', alignItems: 'center' }}>
        <img 
          style={{ borderRadius: '50%', height:80, width: 80, marginRight: 10 }}
          alt="logo" src={Logo} />
          <div>
            <Typography variant="body">E-MAIL:identidadedociclista@gmail.com</Typography>
            <p/>
            <Typography variant="body">Whatsapp:(61) 98284-8251</Typography>
          </div>
      </div>
      <div className={classes.apoio}>
       <Typography variant="body">SE FOR PEDALAR, LEVE SEMPRE A SUA CARTEIRINHA</Typography>
      </div>
    </div>
   

    </div>
   
  );
};

export default Dashboard;
