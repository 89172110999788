import React from 'react';
import {  useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import Triste from './images/EmotionTriste.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center',
    justifyContent: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '30%',
    width: 400,
    borderRadius: 100
  }
}));

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory()

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Typography variant="h5">
              404  Página Não Encontrada!
            </Typography>
            <Typography variant="subtitle2">
              Desulpa, A página que vocẽ está procurando não existe nesse sistema
            </Typography>
            <img
              alt=":("
              className={classes.image}
              src={Triste}
            />
            <Link onClick={() => history.push('/')}>Volta para home</Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
