import React from "react";
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";

export function inputTelefoneFixo(props) {
  const propriedades = { ...props };
  return (
    <NumberFormat
      {...propriedades}
      customInput={TextField}
      format="(##)####-####"
    />
  );
}

export function inputCelular(props) {
  const propriedades = { ...props };
  return (
    <NumberFormat
      {...propriedades}
      customInput={TextField}
      format="(##)#####-####"
    />
  );
}

export default inputTelefoneFixo;
