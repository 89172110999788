
  function convertBase64ToBlob (base64, contentType) {
    let byteCharacters = window.atob(base64)
    let sliceSize = base64.length
    let byteArrays = []
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
  
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
  
    return new Blob(byteArrays, { type: contentType })
  }

  export function gerarDocumento (documento, id, nomeDocumento) {
    let blob = convertBase64ToBlob(documento.data, 'application/pdf')
    let link = document.createElement('a')
    link.id = id
    link.href = window.URL.createObjectURL(blob)
    link.download = nomeDocumento
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }