import axios from "axios";
import reduxStore from "../store";
import { addLoading } from "../store/modules/loading/action";

const { dispatch } = reduxStore;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});


// Definição dos métodos HTTP
const get = (url, parametros, mostrarProgresso = true) => {
  mostrarProgresso && dispatch(addLoading());

  if (!parametros) {
    return axiosInstance.get(url);
  }

  return axiosInstance.get(url, { params: parametros });
};

const post = (url, dados, mostrarProgresso = true) => {
  mostrarProgresso && dispatch(addLoading());
  return axiosInstance.post(url, dados);
};

const put = (url, dados, mostrarProgresso = true) => {
  mostrarProgresso && dispatch(addLoading());
  return axiosInstance.put(url, dados);
};

const httpDelete = (url, config, mostrarProgresso = true) => {
  mostrarProgresso && dispatch(addLoading());
  return axiosInstance.delete(url, config);
};

// Exposição dos métodos HTTP do axios
const api = {
  get,
  post,
  put,
  delete: httpDelete,
};

export default api;
