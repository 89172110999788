import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import api from '../../services/api'
import If from '../../util/If'
import { Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '80%'
    },
    form: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        background: "#f4f6f8",
        padding: 20,
        width:'95%',
        marginTop: '1%',
        maxWidth: 500
    }
  }));


const ValidaAlteracao = () => {
    const classes = useStyles();
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [chave, setChave] = useState('')
    const [tenhoSenha, setTenhoSenha] = useState(true)
    const [showMessage, setShowMessage] = useState(false)  


    const handlePesquisaPorEEmail = () => {

      if(!email) {
        toast.error("A email é obrigatório")
        return
      }
      api.get(`/api/ciclistas/alteracao-cadastro/email/${email}`)
      .then(response => {
        toast.warn('Hey, enviamos um  email para você com o link para alteração do seu cadastro.')
        setShowMessage(true)
      }).catch(err => {
        toast.error(err.response.data.message)        
        setShowMessage(false)
      })     
    }

    const handlePesquisa = () => {
      if(!tenhoSenha) {
        handlePesquisaPorEEmail()
        return
      }

      if(!chave) {
        toast.error("A senha é obrigatória")
        return
      }

      if(!email) {
        toast.error("A email é obrigatório")
        return
      }

      api.get(`/api/ciclistas/email/${email}/chave/${chave}`)
      .then(response => {
          if(response.data) {
            history.push(`/formulario-edicao-ciclista?codMatricula=${response.data.matricula}`)
          }
      }).catch(err => {
        toast.error(err.response.data.message)        
        setShowMessage(false)
      })     
    }

    return (
        <div className={classes.container} >
          <If test={showMessage}>
            <div style={{ borderRadius: 8, color: '#FFF', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '50%', margin: 15, background: '#FFC300' }}>
              <Typography variant="h6">
                Hey, enviamos um e-mail para você  verifique sua caixa de entrada
              </Typography>
              <Typography>
                Não se esqueça de verificar também na caixa de  spam!
              </Typography>
              </div>
          </If>

            <Card className={classes.form}>
            <Typography>  
                   ALTERAÇÃO DE CADASTRO
            </Typography>
             <Link style={{ display: 'flex', padding:10, alignItems: 'center' }} href="#" onClick={() => history.push('/')}>
               <KeyboardBackspaceIcon style={{ marginRight: 10 }} />
                <Typography>  
                   voltar
                </Typography>
             </Link>
              <div style={{ margin: 2, }}>
                <Typography variant="subtitle1">
                    Para continua com a alteração dos seus dados, vamos precisar do seu e-mail para confirma o seu cadastro....
                </Typography>
              </div>

                <div>
                  <Typography variant="subtitle1">
                   DIGITE O SEU EMAIL
                  </Typography>
                  <TextField placeholder="Ex:ciclista@gmail.com" onChange={(event) => setEmail(event.target.value) } size="small" variant="outlined" fullWidth/>
                  {
                    tenhoSenha && (
                      <div>
                       <Typography variant="subtitle1">
                        SENHA (Solicitada na hora que você se cadastrou)
                       </Typography>
                       <TextField type="password" onChange={(event) => setChave(event.target.value) } size="small" variant="outlined" fullWidth/>
                      </div>
                    )
                  }
                
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        defaultChecked
                        checked={tenhoSenha}
                        onChange={(event) => setTenhoSenha(event.target.checked)}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <Typography variant="subtitle1">
                       Eu tenho uma senha
                      </Typography>
                  </div>
                  
                  <Button 
                          fullWidth
                          onClick={() => handlePesquisa()}
                          style={{marginTop: 10, backgroundColor:'#FFC300', color: '#ffff' }}
                   >Confirmar </Button>
                </div>
            </Card>        
        </div>
    )
}

export default ValidaAlteracao;