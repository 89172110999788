export function addLoading() {
  return {
    type: "@ADD_LOADING",
    payload: "",
  };
}

export function removeLoading() {
  return {
    type: "@REMOVE_LOADING",
    payload: "",
  };
}
