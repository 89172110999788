const tokenArmazenado = localStorage.getItem("token");
const INITIAL_STATE = {
  token: tokenArmazenado || null,
  isAutenticado: !!tokenArmazenado,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@ADD_TOKEN":
      return {
        ...state,
        token: action.payload,
        isAutenticado: true,
      };
    case "@REMOVE_TOKEN": {
      return {
        ...state,
        token: "",
        isAutenticado: false,
      };
    }
    default: {
      return state;
    }
  }
}
