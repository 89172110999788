import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import api from '../../services/api'
import If from '../../util/If'
import { Button } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import queryString from 'query-string'

const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '80%'
    },
    form: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        background: "#f4f6f8",
        padding: 20,
        width:'95%',
        marginTop: '1%',
        maxWidth: 500
    }
  }));


const NovaSenhaGrupo = () => {
  const { search } = window.location
  const { meuEmail, codValidacao } = queryString.parse(search)

    const classes = useStyles();
    const history = useHistory()
    const [email, setEmail] = useState(meuEmail || '')
    const [senha, setSenha] = useState('')
    const [showMessage, setShowMessage] = useState(false)  


    const handleRecuperaSenha = () => {

      if(!email) {
        toast.error("A email é obrigatório")
        return
      }
      api.get(`/api/grupos/recupera/senha/email/${email}`)
      .then(response => {
        toast.warn('Hey, enviamos um  email para você com o link para alteração da sua senha.')
        setShowMessage(true)
      }).catch(err => {
        toast.error(err.response.data.message)        
        setShowMessage(false)
      })     
    }

    const handleNovaSenhaSenha = () => {

      if(!senha) {
        toast.error("A senha é obrigatória")
        return
      }
      api.post(`/api/grupos/email/${email}/codseg/${codValidacao}/nova-senha/${senha}`)
      .then(response => {
        toast.success("Nova senha criada com sucesso!")
        history.push('/grupo-login')
      }).catch(err => {
        toast.error(err.response.data ? err.response.data.message : 'Operação falhou se o problema  continua favor procura o administrador do sistema')        
      })     
    }

  
    return (
        <div className={classes.container} >
          <If test={showMessage}>
            <div style={{ borderRadius: 8, color: '#FFF', display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '50%', margin: 15, background: '#FFC300' }}>
              <Typography variant="h6">
                Hey, enviamos um e-mail para você  verifique sua caixa de entrada
              </Typography>
              <Typography>
                Não se esqueça de verificar também na caixa de  spam!
              </Typography>
              </div>
          </If>

            <Card className={classes.form}>
            <Typography>  
                   CRIAR NOVA SENHA
            </Typography>
             <Link style={{ display: 'flex', padding:10, alignItems: 'center' }} href="#" onClick={() => history.push('/grupo-login')}>
               <KeyboardBackspaceIcon style={{ marginRight: 10 }} />
                <Typography>  
                   voltar
                </Typography>
             </Link>
              <div style={{ margin: 2, }}>
                <Typography variant="subtitle1">
                    Para continua com a alteração da sua senha, vamos precisar do seu e-mail para confirma o seu cadastro....
                </Typography>
              </div>

                <div>
                  <Typography variant="subtitle1">
                   DIGITE O SEU EMAIL
                  </Typography>
                  <TextField placeholder="Ex:ciclista@gmail.com" onChange={(event) => setEmail(event.target.value) } size="small" variant="outlined" fullWidth/>
               
                {
                  codValidacao && (
                    <div>
                      <Typography variant="subtitle1">
                        DIGITE UMA NOVA SENHA
                      </Typography>
                      <TextField  type="password" onChange={(event) => setSenha(event.target.value) } size="small" variant="outlined" fullWidth/>
                    </div>
                  )
                }
                  
                  <Button 
                          fullWidth
                          onClick={() => codValidacao ? handleNovaSenhaSenha() : handleRecuperaSenha()}
                          style={{marginTop: 10, backgroundColor:'#FFC300', color: '#ffff' }}
                   >CONFIRMAR </Button>
                </div>
            </Card>        
        </div>
    )
}

export default NovaSenhaGrupo;