import React, { useState, useEffect } from 'react'

import queryString from 'query-string'
import api from '../../services/api'
import If from '../../util/If'
import FormularioCadastroGrupo from './FormularioCadastroGrupo'

const FormularioEdicaoGrupo = () => {
    const [objetoInicial, setObjetoInicial] = useState('')
    const { search } = window.location
    const { email } = queryString.parse(search)

    useEffect(() => {
        if(!email) {
          return;
        }
        api.get(`/api/grupos/email/${email}`)
        .then((response) => {
          setObjetoInicial(response.data)
        }).catch(err => {
          console.log('error', err.response.data)
        })
      },[email])
    return (
        <If test={objetoInicial}>
            <FormularioCadastroGrupo value={objetoInicial} />
        </If>
    )
}

export default FormularioEdicaoGrupo