import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox"
import { TextField } from "@material-ui/core";

import { toast } from "react-toastify";
import queryString from 'query-string';

import idCard from "../imagens/card.jpg";
import api from '../../services/api'
import If from '../../util/If'

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: "280px",
    marginTop: 30,
    padding: 13,
  },
  imagem: {
    marginTop: 50,
    width: '50%',
    height: '50%',
    borderRadius: '50%',
    maxWidth: 300,
    maxHeight: 300
    
  },
}));

export function Identidade(props) {
  const { search } = window.location
  const { codMatricula } = queryString.parse(search)
  const classes = styles();
  const [matricula, setMatricula] = useState(codMatricula || '');
  const [email, setEmail] = useState('') 
  const [isEmail, setIsEmail] = useState(false)

  function convertBase64ToBlob (base64, contentType) {
    let byteCharacters = window.atob(base64)
    let sliceSize = base64.length
    let byteArrays = []
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize)
      let byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }
  
      let byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }
  
    return new Blob(byteArrays, { type: contentType })
  }

  function gerarDocumento (documento, id, nomeDocumento) {
    let blob = convertBase64ToBlob(documento.data, 'application/pdf')
    let link = document.createElement('a')
    link.id = id
    link.href = window.URL.createObjectURL(blob)
    link.download = nomeDocumento
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  

  const handleConsultaPorMatricula = (async () => {
    if (!matricula) {
      toast.error("A matrícula é obrigatória");
      return;
    }
    api.get(`/api/ciclistas/gerar-id/${matricula}`)
    .then((response) => {
        handleGerarIdentidade(response)
      }).catch(err => {
        console.log(err)
      }) 
  });

  const handleConsultaPorEmail = async () => {
    if (!email) {
      toast.error("O email é obrigatória");
      return;
    }
    api.get(`/api/ciclistas/gerar-id/email/${email}`)
    .then((response) => {
        handleGerarIdentidade(response)
      }).catch(err => {
        console.log(err)
      }) 
  };

  const handleConsulta =  () => {
    if (isEmail) {
      handleConsultaPorEmail()
    } else {
      handleConsultaPorMatricula()
    }
  }

  const handleGerarIdentidade = async (identidade) => {
    let response = ''
    if(isEmail) {
     response = await api.get(`/api/ciclistas/email/${email}`)
    } else {
      response = await api.get(`/api/ciclistas/matricula/${matricula}`)
    }
    gerarDocumento(identidade, '10',`IDENTIDADE-CICLISTA- ${response.data.nome}`)
    toast.info(`Hey ${response.data.nome} sua carteirinha foi gerada com sucesso!`)
  }

  useEffect(() => {
    codMatricula && handleConsultaPorMatricula()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
            <div className={classes.container}>
              <img
                  className={classes.imagem}
                  alt="Bora Bora"
                  src={idCard}
                />

              <Typography>Entre com o número da sua matrícula ou o seu email para gera a sua carteirinha</Typography>
              
              <div>
                <If test={!isEmail}>
                  <TextField
                    fullWidth
                    placeholder="MATRICULA"
                    size="small"
                    focused
                    autoFocus
                    inputProps={{style: {fontSize: "20px", textAlign: 'center'}}}
                    variant="outlined"
                    type="text"
                    value={matricula}
                    onChange={(event) => setMatricula(event.target.value)}
                  />
                </If>
                
                <If test={isEmail}>
                  <TextField
                    fullWidth
                    placeholder="ciclista@gmail.com"
                    size="small"
                    focused
                    autoFocus
                    inputProps={{style: {fontSize: "20px", textAlign: 'center'}}}
                    variant="outlined"
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </If>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
                <Checkbox checked={isEmail} onChange={() => setIsEmail(!isEmail)}/>
                <Typography>
                  Não lembro minha matrícula quero usar o meu email 
                </Typography>
                </div>
              
                <Button
                  onClick={() => handleConsulta()}
                  size="large"
                  color="primary"
                  variant="contained"
                  textSizeLarge
                  style={{marginTop: 10, backgroundColor:'#FFC300', color: '#ffff' }}
                  fullWidth
                >
                  GERAR CARTEIRINHA
                </Button>
              </div>
  
          </div> 
  );
}

export default Identidade;
